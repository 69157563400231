import { Testimonials } from "./Testimonials";
import { Banner } from "./Banner";
import { Outcomes } from "./Outcomes";
import { WhyReachifyMe } from "./WhyReachifyMe";
import { Benefits } from "./Benefits";
import React from "react";
import {
  benefitsCardContent,
  keyOutcomes,
  outcomesStats,
  testimonialsCardContent,
  whyReachifyMeCardContent,
} from "./Constant";
import { getValueText } from "../../../../utils/payment";
import StillConfused from "./StillConfused";

const PricingLandingDescription = ({
  setCurrentStep,
  setOpen,
  displayRazorpay,
  currentStep,
  isRazorpayModalOpen,
  setIsRazorpayModalOpen,
  initSubscriptionHandler,
  initializingPayment,
  paymentButtonDisabled,
  availableSubscriptions,
  isMobile,
}) => {
  const efficientPlan = () => {
    let plan = null;
    let price = 0;
    availableSubscriptions?.forEach((p) => {
      if (p.discountedPrice > price) {
        price = p.discountedPrice;
        plan = p;
      }
    });
    return plan;
  };
  const yearlyPlan = availableSubscriptions && efficientPlan();
  const nf =
    yearlyPlan &&
    new Intl.NumberFormat("en-UK", {
      style: "currency",
      currency: yearlyPlan?.currencyCode,
      currencyDisplay: "narrowSymbol",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      trailingZeroDisplay: "stripIfInteger",
    });
  let yearlyPlanPrice =
    (yearlyPlan &&
      getValueText(
        yearlyPlan.validationPeriodType,
        yearlyPlan.discountedPrice / yearlyPlan.priceConversionFactor,
        yearlyPlan.interval
      ).value) ||
    1000;
  let actualPrice =
    yearlyPlan &&
    getValueText(
      yearlyPlan.validationPeriodType,
      yearlyPlan.price / yearlyPlan.priceConversionFactor,
      yearlyPlan.interval
    ).value;

  let unit = "";
  const priceConvert = (x) => {
    if (x > 9999) {
      unit = "K";
      return Math.ceil(x / 1000);
    }
    return x;
  };

  const reachifyMePrice =
    availableSubscriptions && nf?.format(priceConvert(yearlyPlanPrice)) + unit;
  const agencyPriceMonthly =
    availableSubscriptions && nf?.format(priceConvert(actualPrice * 40)) + unit;
  const agencyPriceYearly =
    availableSubscriptions &&
    nf?.format(priceConvert(actualPrice * 40 * 12)) + unit;
  const subscription = availableSubscriptions && efficientPlan();
  return (
    <div className="left-main-container no-scrollbar">
      {/*  why reachify me? */}
      <WhyReachifyMe
        whyReachifyMeCardContent={whyReachifyMeCardContent}
        reachifyMePrice={reachifyMePrice}
        agencyPriceMonthly={agencyPriceMonthly}
        agencyPriceYearly={agencyPriceYearly}
      />
      {/* benefits */}
      <Benefits benefitsCardContent={benefitsCardContent} isMobile={isMobile} />

      {/* banner/offer */}
      <Banner
        subscription={subscription}
        initSubscriptionHandler={initSubscriptionHandler}
        initializingPayment={initializingPayment}
        paymentButtonDisabled={paymentButtonDisabled}
        availableSubscriptions={availableSubscriptions}
        isMobile={isMobile}
      />
      {/* outcomes */}
      <Outcomes
        keyOutcomes={keyOutcomes}
        stats={outcomesStats}
        isMobile={isMobile}
      />
      <StillConfused isMobile={isMobile}/>
      {/* testimonials */}
      <Testimonials testimonialsCardContent={testimonialsCardContent} />
    </div>
  );
};

export default PricingLandingDescription;
