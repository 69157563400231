import { createSlice } from "@reduxjs/toolkit";
const styles = {
  MAIN: "MAIN",
  SUCCESS: "SUCCESS",
  WARNING: "WARNING",
  ERROR: "ERROR",
};
const toastSlice = createSlice({
  name: "toast",
  initialState: {
    showToast: false,
    message: "",
    style: "MAIN",
    position: "top-center",
    showCloseButton: true,
    duration: 3000,
    styles: {
      top: "80px",
      left: "50%",
      width: "480px",
    },
    toastProps: null,
  },
  reducers: {
    showToaster: (state, action) => {
      if (action.payload.style in styles) {
        state.showToast = true;
        state.message = action.payload.message;
        state.style = action.payload.style;
        state.showCloseButton = action.payload.showCloseButton;
        state.position = action.payload.position;
        state.toastProps = action.payload.toastProps;
      } else {
        console.error("Invalid style provided");
      }
    },
    hideToaster: (state) => {
      state.showToast = false;
    },
  },
});

export default toastSlice.reducer;
export const { showToaster, hideToaster } = toastSlice.actions;
