import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Toaster.scss";
import useOutsideClickEvent from "../../../customHooks/useOutsideClickEvent";
import { hideToaster } from "../../../reducers/slices/toastSlice";
import { Close } from "@mui/icons-material";

const ReachifymeToaster = () => {
  const dispatch = useDispatch();
  const timeoutId = useRef(null);
  const { ref } = useOutsideClickEvent(() => {
    dispatch(hideToaster());
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
  });
  const toast = useSelector((state) => state.toast);

  useEffect(() => {
    if (toast.showToast) {
      timeoutId.current = setTimeout(() => {
        dispatch(hideToaster());
      }, 4000);
    }
  }, [toast.showToast]);
  return (
    <div
      style={{
        position: "fixed",
        top: "0",
        width: "100%",
      }}
    >
      <div
        style={{
          postion: "relative",
          width: "100%",
        }}
      >
        {toast.showToast && (
          <div
            ref={ref}
            style={{
              width: "fit-content",
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%) translateY(80px)",
              backgroundColor: "#f3b453",
              border: "1px solid #ef7d3d",
              color: "#121212",
              fontSize: "12px",
              fontFamily: "Poppins",
              fontWeight: "500",
              textAlign: "center",
              padding: "12px 24px",
              marginBottom: "6px",
              borderRadius: "6px",
              zIndex: "10000",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              animation: "0.4s ease-in-out toast-in",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "12px",
              }}
            >
              <div
                style={{
                  flexGrow: "1",
                }}
              >
                {toast.message}
              </div>

              <Close
                onClick={() => {
                  dispatch(hideToaster());
                  if (timeoutId.current) {
                    clearTimeout(timeoutId.current);
                  }
                }}
                sx={{
                  width: "20px",
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReachifymeToaster;
