import React from "react";
export function Outcomes({ keyOutcomes, stats,isMobile }) {
  return (
    <div className="outcomes-main-container">
      {!isMobile && (
        <div>
          <div className="outcomes-heading">
            What will your growth look like?
          </div>
          <div className="outcomes-sub-heading">
            With consistent posts using ReachifyMe, this is what your LinkedIn
            Numbers can look like.
          </div>
        </div>
      )}
      <div className="outcomes-analytics-container">
        <div className="outcomes-mobile-heading">
          <div className="outcomes-heading">Outcomes</div>
          <div className="outcomes-sub-heading">
            With consistent posts using ReachifyMe, this is what your LinkedIn
            Numbers can look like.
          </div>
        </div>
        <div className="key-outcomes-main-container">
          {keyOutcomes.map((point, index) => (
            <div key={index}>
              <KeyOutcomes point={point} />
            </div>
          ))}
        </div>
        <div className="before-after-container">
          <div className="before-after-grid">
            <div className="before-after-grid-heading">Before</div>
            <div>&nbsp;</div>
            <div className="before-after-grid-heading">After</div>
            {stats.map((stat, index) =>
              stat?.before ? (
                <ComparisonCard
                  before={stat.before}
                  text={stat.text}
                  key={index}
                  time={stat.time}
                  percentageDown={stat.percentageDown}
                  arrowImg="/image/pricing/decrement-arrow.svg"
                  style={{
                    border: "1px solid #BEBEBE",
                  }}
                  color="#F44F27"
                />
              ) : stat?.img ? (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "10px",
                    justifyContent: "center",
                  }}
                >
                  <img
                    className="before-after-grid-image"
                    src={stat.img}
                    alt="stat-image"
                  />
                  <img
                    className="before-after-grid-arrow-image"
                    src="/image/pricing/multiple-arrow.svg"
                    alt="multiple-arrow-image"
                  />
                </div>
              ) : (
                stat?.after && (
                  <ComparisonCard
                    key={index}
                    before={stat.after}
                    text={stat.text}
                    percentageDown={stat.percentageUp}
                    style={{
                      border: "1px solid #E1791C",
                      background: "#FFFDF5",
                        }}
                        time={stat.time}
                    arrowImg="/image/pricing/increment-arrow.svg"
                    color="#0DAE6B"
                  />
                )
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export function KeyOutcomes({ point }) {
  return (
    <div className="key-outcomes">
      <div className="success-mark-container">
        <img src="/image/pricing/success-mark.svg" alt="success-mark" />
      </div>
      <div className="key-outcomes-point-text">{point}</div>
    </div>
  );
}

export function GraphBar({
  beforeStats,
  afterStats,
  beforeColor,
  afterColor,
  text,
  beforeLineStroke,
  afterLineStroke,
}) {
  const heightOfBeforeBar = (beforeStats / afterStats) * 180;
  const heightOfAfterBar = 180 - heightOfBeforeBar;
  const percentage = (((afterStats - beforeStats) / beforeStats) * 100).toFixed(
    1
  );
  return (
    <div className="graph-main-container">
      <div className="bar-container">
        <div className="before-bar-container">
          <div className="before-bar-stats">{beforeStats}</div>
          <div
            className="before-bar"
            style={{
              background: `repeating-linear-gradient(135deg, ${beforeColor}, ${beforeColor} 10px,${beforeLineStroke} 10px, ${beforeLineStroke} 12px)`,
              height: heightOfBeforeBar,
              borderTopLeftRadius: "5px",
            }}
          ></div>
        </div>
        <div className="before-bar-container">
          <div className="before-bar-stats">{afterStats}</div>
          <div
            className="before-bar"
            style={{ borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}
          >
            <div
              className="before-bar-1"
              style={{
                background: `repeating-linear-gradient(135deg, ${afterColor}, ${afterColor} 10px,${afterLineStroke} 10px, ${afterLineStroke} 12px)`,
                height: heightOfAfterBar,
                borderTopRightRadius: "5px",
                borderTopLeftRadius: "5px",
                borderBottom: "2px solid #EBB700",
              }}
            ></div>
            <div
              className="before-bar-2"
              style={{
                background: afterColor,
                height: heightOfBeforeBar,
              }}
            ></div>
          </div>
        </div>
      </div>
      <div className="graph-text-container">{text}</div>
      <div className="percentage-circle">
        <div className="increment-image">
          <img src="/image/pricing/increment-arrow.svg" alt="increment-arrow" />
        </div>
        <div className="percentage-text">{percentage}%</div>
      </div>
    </div>
  );
}

export function ComparisonCard({
  before,
  text,
  percentageDown,
  arrowImg,
  style,
  color,
  time
}) {
  return (
    <div className="comparison-card-container" style={style}>
      <div className="comparison-card-data">{before}</div>
      <div className="comparison-card-text">{text}</div>
      <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
        <img src={arrowImg} alt="arrow-image" className="comparison-card-arrow" />
        <div
          className="comparison-card-percentage-data"
          style={{ color: color }}
        >
          {percentageDown}
        </div>
        <div className="comparison-card-time">{time}</div>
      </div>
    </div>
  );
}
