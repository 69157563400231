import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { GoogleOAuthProvider } from "@react-oauth/google";
import ReactGA from "react-ga4";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { RE_CAPTCHA_CLIENT_KEY } from "./constant/constants";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

//Todo: Add your Google client ID here
const client_ID =
  "1003128991699-m9i6kgmnfior1pk87fp9lsv97mpkn7lu.apps.googleusercontent.com";
ReactGA.initialize("G-SMTPT5WLZB");
if (process.env.NODE_ENV !== "development") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}
const root = ReactDOM.createRoot(document.getElementById("root"));
const queryClient = new QueryClient();
root.render(
  <GoogleReCaptchaProvider
    reCaptchaKey={RE_CAPTCHA_CLIENT_KEY}
    useEnterprise={true}
  >
    <GoogleOAuthProvider
      clientId={client_ID}
      redirectUri={`${window.location.origin}/login`}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </LocalizationProvider>
    </GoogleOAuthProvider>
  </GoogleReCaptchaProvider>,
);

// registerServiceWorker();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
