import React from "react";
import { showmaximumDiscountPercentageInSuggestedPlans } from "../../../../utils/payment";
import Buttons from "../../../custombuttons/Buttons";
export function Banner({
  subscription,
  initSubscriptionHandler,
  initializingPayment,
  paymentButtonDisabled,
  availableSubscriptions,
  isMobile
}) {
  return (
    <div className="banner-container">
      <div className="banner-img">
        <img
          src="/image/pricing/banner-excited.svg"
          alt="banner_image"
          style={{ height: "96px", transform: "translateY(3.2px)" }}
        />
      </div>
      <div className="banner-text-container">
        <div className="banner-text">
          Let's
          <div>
            <div
              style={{
                position: "relative",
                display: "flex",
              }}
            >
              <div
                style={{
                  fontWeight: 700,
                  color: "#ffC700",
                }}
              >
                Save{" "}
                {showmaximumDiscountPercentageInSuggestedPlans(
                  availableSubscriptions ?? []
                )}
                %
              </div>
              <img
                src="/image/pricing/underline.svg"
                alt="underline-stroke-image"
                style={{
                  width:isMobile?"65px":"80px",
                  position: "absolute",
                  bottom: "3px",
                  left: "0px",
                }}
              />
            </div>
          </div>
          Today
        </div>
        <div className="banner-text">to a yearly</div>
      </div>
      <div className="banner-button-container">
        <Buttons
          
          variant={
            initializingPayment
              ? "loader"
              : !paymentButtonDisabled
              ? "gradient"
              : "disabled"
          }
          text={"Pay Now "}
          styles={{
            boxShadow: "none",
            background:
              "linear-gradient(91.09deg, #FAE086 8.54%, #F3B453 92.32%)",
            border: "2px solid #F3B453",
            height: "38px",
            width: isMobile ? "100%" : "11.5vw",
            minWidth:isMobile ? "5vw" : "10vw",
            fontSize: "12px",
            fontWeight: "500",
            maxWidth: "175px",
          }}
          clickHandler={() => {
            initSubscriptionHandler(subscription);
          }}
        />
      </div>
    </div>
  );
}
