import React from "react";
import CustomModal from "../../Modal/CustomModal";
import Buttons from "../../custombuttons/Buttons";

const PaymentUpdateConfirmationModal = ({ handleCancel, handleConfirm }) => {
  return (
    <CustomModal open={true} setOpen={handleCancel} width={"500px"}>
      <div
        style={{
          fontSize: "22px",
          fontWeight: "500",
          color: "#34374b",
        }}
      >
        Upgrade Subscription?
      </div>
      <div>
        Your current subscription plan will be adjusted and prorated based on
        the new plan. The new subscription plan will be activated once the
        payment is processed.
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "20px",
          gap: "16px",
        }}
      >
        <Buttons
          text={"Cancel"}
          variant={"outlined"}
          clickHandler={handleCancel}
          styles={{ width: "50%" }}
        />
        <Buttons
          text={"Confirm"}
          variant={"gradient"}
          clickHandler={handleConfirm}
          styles={{ width: "50%" }}
        />
      </div>
    </CustomModal>
  );
};

export default PaymentUpdateConfirmationModal;
