import React, { useState, useEffect, useRef } from "react";
import "./paymentComponents.scss";
import Buttons from "../../custombuttons/Buttons";
import usePaymentApi from "../paymentApi";
import { useDispatch, useSelector } from "react-redux";
import PaymentVerifyingModal from "./PaymentVerifyingModal";
import { Skeleton } from "@mui/material";
import { trustmarkers } from "../../../constant/paymentString";
import { paymentCardFeaturesContent } from "./PricingLandingComponents/Constant";
import { proceedToPaymentClick } from "../../../utils/paymentEvents";
import {
  getValueText,
  showDiscountPercentage,
  showmaximumDiscountPercentageInSuggestedPlans,
} from "../../../utils/payment";
import { setOpenTimer } from "../../../reducers/slices/paymentOfferTimerSlice";
import { usePayment } from "../provider/usePayment";
import { PaymentStep } from "../PaymentComponentBox";

const planCardStyle = {
  notSelected: {
    border: "1px solid #6366F11A",
    background: "#ECECFF",
    color: "#0C1620",
  },
  selected: {
    border: "1px solid #6366F1",
    background: "#6366F1",
    color: "#FFFFFF",
    boxShadow: "8px 6px 6px 0px #0008FF0F",
  },
};

const PaymentPlanCard = ({
  msg,
  setOpen,
  paymentComponentType,
  useCase,
  initSubscriptionHandler,
}) => {
  const { step, initPaymentFlow } = usePayment();
  const dispatch = useDispatch();
  const testimonialsRef = useRef(null);
  const activeSubscription = useSelector(
    (state) => state.payment.activeSubscription,
  );
  const availableSubscriptions = useSelector(
    (state) => state.payment.suggestedPlans,
  );
  const closeTimer = useSelector((state) => state.paymentOfferTimer.closeTimer);
  const openTimer = useSelector((state) => state.paymentOfferTimer.openTimer);
  const currentTime = new Date().getTime();
  const actualTimeSpentWhileModalOpen = currentTime - openTimer;
  var sec = Math.floor((actualTimeSpentWhileModalOpen / 1000) % 60);
  var min = Math.floor((actualTimeSpentWhileModalOpen / (1000 * 60)) % 60);
  if (min > 30) {
    const today = new Date().getTime();
    dispatch(setOpenTimer(today));
    min = 30;
    sec = 0;
  } else {
    min = 30 - min;
  }
  sec = 59 - sec;

  const userId = useSelector((state) => state.userData?.userId);
  const isMobile = useSelector((state) => state.device.isMobileDevice);
  const [isAvailableSubscriptionsLoading, setIsAvailableSubscriptionsLoading] =
    useState(false);
  const [paymentButtonDisabled, setPaymentButtonDisabled] = useState(false);
  // const [availableSubscriptions, setAvailableSubscriptions] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [initializingPayment, setInitializingPayment] = useState(false);
  const [isVerifyingPayment, setIsVerifyingPayment] = useState(false);
  const [minutes, setMinutes] = useState(min || 30);
  const [isHovered, setIsHovered] = useState(false);
  const [seconds, setSeconds] = useState(sec || 59);
  const { getAvailableSubscriptions, initSubscription } = usePaymentApi();
  useEffect(() => {
    if (!availableSubscriptions) {
      getAvailableSubscriptions({
        setIsLoading: setIsAvailableSubscriptionsLoading,
        setAvailableSubscriptions: () => {},
        setOpen,
      });
    }
  }, [activeSubscription]);

  useEffect(() => {
    if (availableSubscriptions && availableSubscriptions.length > 0) {
      setSelectedPlan(
        availableSubscriptions[availableSubscriptions.length - 1],
      );
    }
  }, [availableSubscriptions]);
  useEffect(() => {
    const handleBeforeUnload = (event) => {};
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const selectedPlanHandler = (plan) => {
    setSelectedPlan(plan);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const slideTestimonials = (direction) => {
    const testimonials = testimonialsRef.current;
    const testimonialsWidth = testimonials.offsetWidth;
    const scrollWidth = testimonials.scrollWidth;
    const scrollLeft = testimonials.scrollLeft;
    const scrollRight = scrollWidth - scrollLeft - testimonialsWidth;
    if (direction === "right") {
      if (scrollRight > testimonialsWidth) {
        testimonials.scrollLeft += testimonialsWidth;
      } else {
        testimonials.scrollLeft += scrollRight;
      }
    } else {
      if (scrollLeft > testimonialsWidth) {
        testimonials.scrollLeft -= testimonialsWidth;
      } else {
        testimonials.scrollLeft -= scrollLeft;
      }
    }
  };

  const getTime = () => {
    if (minutes < 0) {
      const today = new Date().getTime();
      dispatch(setOpenTimer(today));
      setMinutes(30);
      setSeconds(0);
      return;
    }
    if (minutes === 0 && seconds === 0) {
      const today = new Date().getTime();
      dispatch(setOpenTimer(today));
      setMinutes(30);
      setSeconds(0);
      return;
    }
    if (seconds === 0) {
      setMinutes((prev) => prev - 1);
      setSeconds(59);
      return;
    }
    setSeconds((prev) => prev - 1);
  };
  useEffect(() => {
    const interval = setInterval(() => getTime(), 1000);

    return () => clearInterval(interval);
  }, [minutes, seconds]);
  return (
    <div
      className="payment-plan-card"
      style={{
        maxHeight:
          isMobile && paymentComponentType === "static"
            ? "fit-content"
            : isMobile
              ? "80vh"
              : "auto",

        paddingLeft:
          isMobile && paymentComponentType === "static" ? "0px" : "auto",
        paddingRight:
          isMobile && paymentComponentType === "static" ? "0px" : "auto",
      }}
    >
      {useCase !== "upgrade" && msg && (
        <div
          className="payment-plan-card_header"
          style={{
            maxWidth: paymentComponentType === "static" ? "100%" : "96%",
          }}
        >
          {msg || "Your FREE trial has ended"}
        </div>
      )}
      <div className="payment-plan-card_body">
        <div className="payment-plan-card_body_up">
          <div className="payment-plan-card_body_up_title">
            Upgrade your Plan
            {/* {useCase !== "upgrade"
              ? "Unlock Freedom, Upgrade Now!"
              : "Unlock Freedom, Upgrade Now!"} */}
          </div>
          <div className="payment-plan-card_body_up_trustmarker">
            <img
              className="trustmarker-image"
              src="image/payment/AvatarStack.svg"
              alt="plan"
            />
            <span className="trustmarker-text">
              Trusted by{" "}
              <span className="trustmarker-text-highlight">10000+</span>{" "}
              professionals on
            </span>
            <img src="image/payment/linkedInLogo.svg" alt="plan" />
          </div>
          <div>
            {showmaximumDiscountPercentageInSuggestedPlans(
              availableSubscriptions ?? [],
            ) > 0 && (
              <div className="payment-plan-card_body_up_timer">
                <div className="payment-plan-card_body_up_timer_title">
                  <span style={{ color: "#121212" }}>
                    Amazing offer for you
                  </span>{" "}
                  <span className="payment-plan-card_body_up_timer_title_percentage">
                    {showmaximumDiscountPercentageInSuggestedPlans(
                      availableSubscriptions ?? [],
                    )}{" "}
                    %OFF
                  </span>
                </div>
                <div className="payment-plan-card_body_up_timer_text">
                  <div
                    className="payment-plan-card_body_up_timer_text_hurry"
                    style={{
                      fontSize:
                        isMobile && paymentComponentType === "static"
                          ? "12px"
                          : "14px",
                    }}
                  >
                    Hurry up! Limited time only
                  </div>
                  <div className="payment-plan-card_body_up_timer_time">
                    <div className="payment-plan-card_body_up_timer_time_unit">
                      <div className="payment-plan-card_body_up_timer_time_value">
                        00
                      </div>
                      <div className="payment-plan-card_body_up_timer_time_value_unit">
                        HRS
                      </div>
                    </div>
                    :
                    <div className="payment-plan-card_body_up_timer_time_unit">
                      <div className="payment-plan-card_body_up_timer_time_value">
                        {minutes < 10 ? `0${minutes}` : minutes}
                      </div>
                      <div className="payment-plan-card_body_up_timer_time_value_unit">
                        MINS
                      </div>
                    </div>
                    :
                    <div className="payment-plan-card_body_up_timer_time_unit">
                      <div className="payment-plan-card_body_up_timer_time_value">
                        {seconds < 10 ? `0${seconds}` : seconds}
                      </div>
                      <div className="payment-plan-card_body_up_timer_time_value_unit">
                        SEC
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div
            className="payment-plan-card_body_right"
            style={{
              flexDirection: availableSubscriptions?.length === 1 && "row",
              gap: "10px",
            }}
          >
            {isAvailableSubscriptionsLoading ? (
              <>
                <LoaderSkeleton />
                <LoaderSkeleton />
              </>
            ) : (
              availableSubscriptions &&
              availableSubscriptions.map((subscription, index) => {
                const words = subscription.name.trim().split(" ");
                const name = words[words.length - 1];
                const nf = new Intl.NumberFormat("en-UK", {
                  style: "currency",
                  currency: subscription.currencyCode,
                  currencyDisplay: "narrowSymbol",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                  trailingZeroDisplay: "stripIfInteger",
                });
                return (
                  <div
                    className="plan-card"
                    style={
                      selectedPlan === subscription || isHovered
                        ? planCardStyle.selected
                        : planCardStyle.notSelected
                    }
                    key={index}
                    onMouseEnter={() => {
                      if (
                        selectedPlan === subscription ||
                        initializingPayment
                      ) {
                        return;
                      }
                      setIsHovered(true);
                      selectedPlanHandler(subscription);
                      setIsHovered(false);
                    }}
                    onMouseLeave={() => {
                      if (initializingPayment) {
                        return;
                      }
                      setIsHovered(false);
                    }}
                    onClick={() => {
                      if (initializingPayment) {
                        return;
                      }
                      setIsHovered(false);
                      selectedPlanHandler(subscription);
                    }}
                  >
                    <div className="plan-card_inner_box">
                      {subscription.validationPeriodType === "MONTH" &&
                        !isMobile && (
                          <div className="recommended-box-heading-balance"></div>
                        )}
                      <div>
                        <div className="plan-card_title_box">
                          {subscription.validationPeriodType === "YEAR" && (
                            <>
                              {!isMobile ? (
                                <div
                                  className="plan-card_recommended"
                                  style={{
                                    background:
                                      selectedPlan === subscription
                                        ? "#ECECFF"
                                        : "#6366F1",
                                    padding: "0px 10px",
                                    borderRadius: "12px",
                                    color:
                                      selectedPlan === subscription || isHovered
                                        ? "#6366F1"
                                        : "#ECECFF",
                                  }}
                                >
                                  Recommended
                                </div>
                              ) : (
                                <div>
                                  <img
                                    src="/image/payment/star.svg"
                                    alt="highlight-star"
                                  />
                                </div>
                              )}
                            </>
                          )}

                          <div
                            className="plan-card_name"
                            style={{
                              color:
                                selectedPlan === subscription || isHovered
                                  ? planCardStyle.selected.color
                                  : planCardStyle.notSelected.color,
                              marginLeft:
                                subscription.validationPeriodType === "MONTH" &&
                                !isMobile &&
                                "auto",
                              marginRight:
                                subscription.validationPeriodType === "MONTH" &&
                                !isMobile &&
                                "auto",
                            }}
                          >
                            {name} Plan
                          </div>
                          <div className="plan-card_discount-box">
                            <div
                              className="plan-card_discount-left-bookmark"
                              style={{
                                borderLeft:
                                  selectedPlan === subscription || isHovered
                                    ? `9px solid ${planCardStyle.selected.background}`
                                    : `9px solid ${planCardStyle.notSelected.background}`,
                              }}
                            ></div>
                            <div className="plan-card_discount_value">
                              {subscription.price -
                                subscription.discountedPrice >
                              0
                                ? showDiscountPercentage(
                                    subscription.price,
                                    subscription.discountedPrice,
                                  )
                                : " "}
                            </div>
                            <div
                              className="plan-card_discount-right-bookmark"
                              style={{
                                borderRight:
                                  selectedPlan === subscription || isHovered
                                    ? `9px solid ${planCardStyle.selected.background}`
                                    : `9px solid ${planCardStyle.notSelected.background}`,
                              }}
                            ></div>
                          </div>
                        </div>
                        {/* <div className="plan-card_name_subheading">
                          {subscription.price - subscription.discountedPrice > 0
                            ? showDiscountPercentage(
                                subscription.price,
                                subscription.discountedPrice
                              )
                            : " "}
                        </div> */}
                      </div>
                      {subscription.validationPeriodType === "MONTH" &&
                        !isMobile && <div style={{ height: "10px" }}></div>}
                      <div className="plan-card_prices">
                        {/* {subscription?.isSaver && (
                        <div className="plan-card_chip">SAVER</div>
                      )} */}
                        <div>
                          <div className="price_box">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "flex-end",
                              }}
                            >
                              <div
                                className="offered-price"
                                style={{
                                  color:
                                    selectedPlan === subscription || isHovered
                                      ? "#FFFFFF"
                                      : "#3F3B31",
                                }}
                              >
                                {nf.format(
                                  getValueText(
                                    subscription.validationPeriodType,
                                    subscription.discountedPrice /
                                      subscription.priceConversionFactor,
                                    subscription.interval,
                                  ).value,
                                )}
                              </div>
                              <div
                                className="offered-price-text"
                                style={{
                                  color:
                                    selectedPlan === subscription || isHovered
                                      ? "#FFFFFF"
                                      : "#3F3B31",
                                }}
                              >
                                /month{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="plan-card-discount-actual-price">
                          <div
                            className="actual-price"
                            style={{
                              color:
                                selectedPlan === subscription || isHovered
                                  ? "#D5D5D5"
                                  : "#808080",
                            }}
                          >
                            {/* {nf.format(
                            subscription.price /
                              subscription.priceConversionFactor
                          )} */}
                            {nf.format(
                              getValueText(
                                subscription.validationPeriodType,
                                subscription.price /
                                  subscription.priceConversionFactor,
                                subscription.interval,
                              ).value,
                            )}
                          </div>
                          <div className="plan-card_discount-box">
                            <div
                              className="plan-card_discount-left-bookmark"
                              style={{
                                borderLeft:
                                  selectedPlan === subscription || isHovered
                                    ? `9px solid ${planCardStyle.selected.background}`
                                    : `9px solid ${planCardStyle.notSelected.background}`,
                              }}
                            ></div>
                            <div className="plan-card_discount_value">
                              {subscription.price -
                                subscription.discountedPrice >
                              0
                                ? showDiscountPercentage(
                                    subscription.price,
                                    subscription.discountedPrice,
                                  )
                                : " "}
                            </div>
                            <div
                              className="plan-card_discount-right-bookmark"
                              style={{
                                borderRight:
                                  selectedPlan === subscription || isHovered
                                    ? `9px solid ${planCardStyle.selected.background}`
                                    : `9px solid ${planCardStyle.notSelected.background}`,
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="price-label"
                        style={{
                          color:
                            selectedPlan === subscription || isHovered
                              ? "#EDEDED"
                              : "#3F3B31",
                        }}
                      >
                        {
                          getValueText(
                            subscription.validationPeriodType,
                            subscription.discountedPrice /
                              subscription.priceConversionFactor,
                            subscription.interval,
                          ).text
                        }
                        {/* per{" "}
                        {subscription.interval > 1 ? subscription.interval : ""}{" "}
                        {subscription.validationPeriodType.toLowerCase()} */}
                      </div>
                      {subscription.validationPeriodType === "YEAR" ? (
                        <div className="recommended-card-highlight-feature-box">
                          <div className="recommended-card-highlight-feature">
                            {!isMobile && (
                              <img
                                src="/image/payment/star.svg"
                                alt="highlighted-feature"
                                style={{ width: "12px", height: "12px" }}
                              />
                            )}
                            <div
                              className="recommended-feature-text"
                              style={{
                                color:
                                  selectedPlan === subscription
                                    ? "#FFFFFF"
                                    : "#3F3B31",
                              }}
                            >
                              + Free access to
                            </div>
                            {!isMobile && (
                              <img
                                src="/image/payment/star.svg"
                                alt="highlighted-feature"
                                style={{ width: "12px", height: "12px" }}
                              />
                            )}
                          </div>
                          <div
                            className="recommended-feature-text"
                            style={{
                              color:
                                selectedPlan === subscription
                                  ? "#FFFFFF"
                                  : "#3F3B31",
                            }}
                          >
                            Onboarding call, LinkedIn growth guide & Community
                          </div>
                        </div>
                      ) : (
                        !isMobile && <div style={{ height: "16px" }}></div>
                      )}
                    </div>
                    <div>
                      <Buttons
                        key={index}
                        variant={
                          step === PaymentStep.INITIATION &&
                          selectedPlan === subscription
                            ? "loader"
                            : !paymentButtonDisabled
                              ? "gradient"
                              : "disabled"
                        }
                        text={"Pay Now "}
                        styles={{
                          boxShadow: "none",
                          background:
                            selectedPlan === subscription || isHovered
                              ? "linear-gradient(91.09deg, #FAE086 8.54%, #F3B453 92.32%)"
                              : "#ECECFF",
                          border:
                            selectedPlan === subscription || isHovered
                              ? "2px solid #F3B453"
                              : "1px solid #111111",
                          borderImageSorce:
                            selectedPlan === subscription || isHovered
                              ? "linear-gradient(91.09deg, #FAE086 8.54%, #F3B453 92.32%)"
                              : "#111111",
                          // height: "50px",
                          height: "48px",

                          width:
                            isMobile && paymentComponentType === "modal"
                              ? "100%"
                              : "100%",
                          // fontSize: "16px",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                        clickHandler={() => {
                          initSubscriptionHandler(subscription);
                        }}
                      />
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
        <div
          className="payment-plan-card_body_down"
          style={{
            marginBottom:
              isMobile && paymentComponentType === "static" ? "0px" : "-32px",
          }}
        >
          <div className="payment-plan-card_body_down_title">
            Features for you
          </div>
          <div className="payment-plan-card_body_down_features">
            {paymentCardFeaturesContent.map((feature, index) => (
              <FeatureText key={index}>{feature.text}</FeatureText>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentPlanCard;

export const LoaderSkeleton = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        border: "1px solid #cccccc",
        padding: "12px",
        borderRadius: "12px",
        background: "#efefef",
        margin: "8px 0px",
      }}
    >
      <Skeleton
        animation="wave"
        variant="rectangular"
        width={"50%"}
        height={35}
        style={{ mx: "12px", borderRadius: "8px" }}
      />
      <div style={{ width: "40%" }}>
        <Skeleton
          animation="wave"
          variant="rectangular"
          width={"100%"}
          height={35}
          style={{ mx: "12px", borderRadius: "8px" }}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          width={"100%"}
          height={25}
          style={{
            mx: "12px",
            borderRadius: "6px",
            marginTop: "8px",
          }}
        />
      </div>
    </div>
  );
};

const FeatureText = ({ children }) => {
  return (
    <div className="feature-text">
      <div>
        <img
          src="image/pricing/success-mark.svg"
          alt="success-mark"
          className="feature-text-icon"
        />
      </div>
      {children}
    </div>
  );
};

{
  /* <div>
              <img
                width={"100%"}
                className="banner-image-mobile"
                //DEFAULT SOURCE
                src="image/payment/banner.svg"
                //INDEPENDANCE DAY SALE
                // src="image/payment/InnerBannerImage.svg"
                alt="banner"
              />
            </div> */
}
{
  /* <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {isMobile && paymentComponentType === "modal" && (
                <Buttons
                  variant={"outlined"}
                  text="Cancel"
                  styles={{ width: "47%", fontSize: "14px" }}
                  clickHandler={() => closeModal(false)}
                />
              )}
              
            </div> */
}
