import React from "react";

const TestimonialCard = ({ profileImg, name, role, country, description,testimonialCardRef }) => {
  return (
    <div className="testimonials-card-container" >
      <div className="testimonials-user-details">
        <div className="testimonials-profile-img">
          <img src={profileImg} alt="profile" />
        </div>
        <div>
          <div style={{ fontSize: "16px", fontWeight: 600, color: "#111111" }}>
            {name}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              fontSize: "12px",
              fontWeight: 400,
              color: "#4F6882",
              gap: "8px",
            }}
          >
            <div style={{textWrap:"nowrap"}}>{role}</div>
            <div
              style={{
                width: "4px",
                height: "4px",
                background: "#CCCCCC",
                borderRadius: "50%",
              }}
            />
            <div>{country}</div>
          </div>
        </div>
      </div>
      <div className="testimonials-description">{description}</div>
    </div>
  );
};

export default TestimonialCard;
