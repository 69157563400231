import React from "react";

const BenefitsCard = ({img,title,description,alt_text}) => {
  return (
    <div className="benefits-card">
      <div>
        <img src={img} alt={alt_text} />
      </div>
      <div className="benefits-card-title">{title}</div>
      <div className="benefits-card-description">
        {description}
      </div>
    </div>
  );
};

export default BenefitsCard;


  