import React from "react";
import CustomModal from "../../Modal/CustomModal";
import Buttons from "../../custombuttons/Buttons";
import Lottie from "lottie-react";
import paymentFailed from "../assets/paymentFailed.json";
import paymentSuccess from "../assets/paymentSuccess.json";
import { useSelector } from "react-redux";
import { paymentStatusModalStrings } from "../../../constant/paymentString";
import { PaymentStep } from "../PaymentComponentBox";

const PaymentStatusModal = ({ paymentStatus, showPaymentCard }) => {
  const isMobile = useSelector((state) => state.device.isMobileDevice);
  const showStatusModal = [
    "CONFIRMATION_FAILED",
    "INITIATION_FAILED",
    "CHECKOUT_FAILED",
    "SUCCESS",
  ].includes(paymentStatus);
  const createNewPost = () => {
    window.location.href = "/";
  };
  const CancelHandler = () => {
    // setCurrentStep(PaymentStep.PAYMENT_CARD);
    showPaymentCard();
  };
  const reloadPage = () => {
    window.location.reload();
  };
  const renderStatusModalComponent = () => {
    switch (paymentStatus) {
      case PaymentStep.SUCCESS:
        return (
          <SuccessComponent createNewPost={createNewPost} isMobile={isMobile} />
        );
      case PaymentStep.CHECKOUT_FAILED:
        return (
          <FailedComponent CancelHandler={CancelHandler} isMobile={isMobile} />
        );
      case PaymentStep.INITIATION_FAILED:
        return (
          <FailedComponent CancelHandler={CancelHandler} isMobile={isMobile} />
        );
      case PaymentStep.CONFIRMATION_FAILED:
        return (
          <FailedComponent CancelHandler={CancelHandler} isMobile={isMobile} />
        );
      default:
        return null;
    }
  };
  return (
    <>
      {showStatusModal && (
        <CustomModal
          open={true}
          setOpen={() => {
            reloadPage();
          }}
          width={"480px"}
          showCancelButton={paymentStatus === "SUCCESS"}
        >
          {renderStatusModalComponent()}
        </CustomModal>
      )}
    </>
  );
};

export default PaymentStatusModal;

const SuccessComponent = ({ createNewPost, isMobile }) => {
  return (
    <div>
      <div
        style={{
          fontFamily: "Poppins",
          fontSize: isMobile ? "22px" : "24px",
          color: "#121212",
          fontWeight: "600",
          marginBottom: "12px",
        }}
      >
        {paymentStatusModalStrings.success.title}
      </div>
      <div
        style={{
          width: "100%",
          borderRadius: "16px",
          height: "240px",
          background:
            "linear-gradient(99.43deg, #EEFFF6 -1.11%, #FFFDD9 102.88%)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Lottie
          animationData={paymentSuccess}
          loop={true}
          style={{ height: "100%", width: "100%" }}
        />
      </div>
      <div
        style={{
          fontSize: isMobile ? "14px" : "18px",
          fontFamily: "Poppins",
          fontWeight: "400",
          color: "#808080",
          margin: "16px 0",
        }}
      >
        {paymentStatusModalStrings.success.description}
      </div>
      <div>
        <Buttons
          text={paymentStatusModalStrings.success.buttonText}
          variant="gradient"
          width={"100%"}
          clickHandler={createNewPost}
        />
      </div>
    </div>
  );
};

const FailedComponent = ({ CancelHandler, isMobile }) => {
  return (
    <div style={{ width: "100%" }}>
      <div style={{ fontSize: isMobile ? "22px" : "24px", fontWeight: "500" }}>
        {paymentStatusModalStrings.failed.title}
      </div>
      <div
        style={{
          width: "100%",
          borderRadius: "16px",
          height: "240px",
          background:
            "linear-gradient(99.43deg, #EEFFF6 -1.11%, #FFdddd 102.88%)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Lottie
          animationData={paymentFailed}
          loop={true}
          style={{ height: "100%", width: "100%" }}
        />
      </div>
      <div style={{ marginTop: "16px" }}>
        <Buttons
          text={paymentStatusModalStrings.failed.buttonText}
          variant="gradient"
          width={"100%"}
          clickHandler={CancelHandler}
        />
      </div>
    </div>
  );
};
