import React from "react";
import BenefitsCard from "./BenefitsCard";

export function Benefits({ benefitsCardContent ,isMobile}) {
  return (
    <div className="benefits-main-container">
      {isMobile ? (
        <div className="benefits-heading">Key Benefits</div>
      ) : (
        <div>
          <div className="benefits-heading">What’s in store?</div>
          <div className="benefits-sub-heading">
            Our users tell us this is definitely value for money. We agree.
            Here’s everything you can get access to with a ReachifyMe
            subscription.
          </div>
        </div>
      )}
      <div className="benefits-card-container">
        {benefitsCardContent.map((item, index) => (
          <div key={index}>
            <BenefitsCard
              img={item.img}
              title={item.title}
              description={item.description}
              img_alt_text={item.img_alt_text}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
