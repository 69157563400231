import { useDispatch, useSelector } from "react-redux";
import { API_URL } from "../../constant/constants";
import useRestClient from "../../utils/RestClient";
import {
  SetSubscriptions,
  SetSuggestedPlans,
} from "../../reducers/slices/paymentSlice";
import {
  ActivateFreePlanIfAvailable,
  subscriptionSuccessfullStatus,
} from "../../utils/payment";
import { toast } from "react-toastify";
import {
  cancelSubscriptionSuccess,
  upgradeSuccess,
} from "../../utils/paymentEvents";
import { timeLeftFromNow } from "../../utils/DateFormatter";
import { paymentComponentBoxStrings } from "../../constant/paymentString";

const usePaymentApi = () => {
  const { get, post, patch } = useRestClient();
  const dispatch = useDispatch();
  const activeSubscription = useSelector(
    (state) => state.payment.activeSubscription,
  );

  const getActivePlan = () => {
    get(`${API_URL}/subscription/user/active-plan`, {
      beforeFetch: () => {},
      onSuccess: (response) => {
        dispatch(
          SetSubscriptions({
            activeSubscription: response?.data?.data?.activeSubscription,
            currentPaymentSubscription:
              response?.data?.data?.currentPaymentSubscription,
            isActive: response?.data?.data?.isActive,
            currentTime: response?.data?.currentTime,
            lastUserSubscription: response?.data?.data?.lastUserSubscription,
            upcomingSubscription: response?.data?.data?.upcomingSubscription,
          }),
        );
      },
      onError: (err) => {},
      finally: () => {},
    });
  };
  const getUpcomingPlan = ({ setUpcomingPlan }) => {
    get(`${API_URL}/subscription/user/active-plan`, {
      beforeFetch: () => {},
      onSuccess: (response) => {
        setUpcomingPlan(response?.data?.data?.upcomingSubscription);
      },
      onError: (err) => {},
      finally: () => {},
    });
  };

  const autoActivateFreePlan = ({
    checkingFreePlan,
    showAlert,
    setIsPaymentModalOpen,
  }) => {
    get(`${API_URL}/subscription/user/suggested-plans`, {
      beforeFetch: () => {
        checkingFreePlan(true);
      },
      onSuccess: (response) => {
        ActivateFreePlanIfAvailable({
          response,
          setIsPaymentModalOpen,
          initSubscription,
          showAlert,
          checkingFreePlan,
        });
      },
      onError: (err) => {
        checkingFreePlan(false);
      },
      finally: () => {},
    });
  };
  const getAvailableSubscriptions = ({
    setIsLoading,
    setAvailableSubscriptions,
    setOpen,
  }) => {
    get(`${API_URL}/subscription/user/suggested-plans`, {
      beforeFetch: () => {
        setIsLoading(true);
      },
      onSuccess: (response) => {
        setAvailableSubscriptions(response?.data?.data);
        dispatch(SetSuggestedPlans(response?.data?.data));
        if (response?.data?.data?.length === 0) {
          setOpen(false);
        }
      },
      onError: (err) => {},
      finally: () => {
        setIsLoading(false);
      },
    });
  };

  const isUpgradeAvailable = ({ setIsPlanCanBeUpgraded }) => {
    get(`${API_URL}/subscription/user/suggested-plans`, {
      beforeFetch: () => {},
      onSuccess: (response) => {
        setIsPlanCanBeUpgraded(response?.data?.data?.length > 0);
      },
      onError: (err) => {},
      finally: () => {},
    });
  };

  const getUserBills = ({ setIsLoading, setUserBills }) => {
    get(`${API_URL}/subscription/user/bills`, {
      beforeFetch: () => {
        setIsLoading(true);
      },
      onSuccess: (response) => {
        setUserBills(response?.data?.data?.list);
      },
      onError: (err) => {},
      finally: () => {
        setIsLoading(false);
      },
    });
  };

  const initSubscription = ({
    planId,
    setInitializingPayment,
    displayRazorpay,
    setCurrentStep,
  }) => {
    post(`${API_URL}/subscription/payment/init`, {
      data: {
        planId,
      },
      beforeFetch: () => {
        setInitializingPayment(true);
      },
      onSuccess: (response) => {
        const res = response?.data?.data;
        if (res.paymentDetails.type === "SUBSCRIPTION") {
          console.log("subscription plan");
          console.log("key", res.paymentGatewayDetails.key);
          console.log(
            "amount",
            res.paymentDetails.subscription.pricingPlan.discountedPrice,
          );
          console.log(
            "currency",
            res.paymentDetails.subscription.pricingPlan.currencyCode,
          );
          console.log("description", res.businessDetails.description);
          console.log("companyName", res.businessDetails.name);
          console.log("logo", res.businessDetails.logo);
          console.log(
            "order_id",
            res.paymentDetails.subscription.pgSubscriptionId,
          );
          console.log("id", res.paymentDetails.subscription.subscriptionId);

          displayRazorpay({
            key: res.paymentGatewayDetails.key,
            amount: res.paymentDetails.subscription.pricingPlan.discountedPrice,
            currency: res.paymentDetails.subscription.pricingPlan.currencyCode,
            description: res.businessDetails.description,
            companyName: res.businessDetails.name,
            logo: res.businessDetails.logo,
            order_id: res.paymentDetails.subscription.pgSubscriptionId,
            id: res.paymentDetails.subscription.subscriptionId,
          });
        } else if (res.paymentDetails.type === "FREE") {
          setCurrentStep(paymentComponentBoxStrings.paymentSuccess);
          getActivePlan();
          getAvailableSubscriptions({
            setIsLoading: () => {},
            setAvailableSubscriptions: () => {},
            setOpen: () => {},
          });
        }
        setInitializingPayment(false);
      },
      onError: (err) => {
        console.log(err);
        setInitializingPayment(false);
        console.log(err?.response?.data?.error?.errors?.error);
        toast.error(err?.response?.data?.error?.errors?.error);
      },
      finally: () => {
        setInitializingPayment(false);
      },
    });
  };

  const cancelSubscription = ({
    subscriptionId,
    setCancelLoader,
    setIsCancelSubscriptionModalOpen,
  }) => {
    patch(
      `${API_URL}/subscription/payment/subscriptions/${subscriptionId}/cancel`,
      {
        beforeFetch: () => {
          setCancelLoader(true);
        },
        onSuccess: (response) => {
          console.log(response?.data?.data);
          getActivePlan();
          setIsCancelSubscriptionModalOpen(false);
          getAvailableSubscriptions({
            setIsLoading: () => {},
            setAvailableSubscriptions: () => {},
            setOpen: () => {},
          });
          cancelSubscriptionSuccess();
        },
        onError: (err) => {},
        finally: () => {
          setCancelLoader(false);
        },
      },
    );
  };

  const getUpdatePaymentMethodLink = ({
    subscriptionId,
    setShowLoader,
    openUpdatePaymentLink,
  }) => {
    get(
      `${API_URL}/subscription/payment/subscriptions/${subscriptionId}/update-payment-method-link`,
      {
        beforeFetch: () => {
          setShowLoader(true);
        },
        onSuccess: (response) => {
          openUpdatePaymentLink(response?.data?.data);
          //setIsPlanCanBeUpgraded(response?.data?.data?.length > 0);
        },
        onError: (err) => {},
        finally: () => {
          setShowLoader(false);
        },
      },
    );
  };

  const isSubscriptionSuccessfull = ({
    orderId,
    setCurrentStep,
    pgSubscriptionId,
    pgPaymentId,
    pgSignature,
  }) => {
    const data = {
      pgSubscriptionId,
      pgPaymentId,
      pgSignature,
    };
    patch(`${API_URL}/subscription/payment/subscriptions/${orderId}/confirm`, {
      data,
      beforeFetch: () => {},
      onSuccess: (response) => {
        if (response?.data?.data?.poll) {
          const interval = response?.data?.data?.interval * 1000;
          setTimeout(
            isSubscriptionSuccessfull({
              orderId,
              setCurrentStep,
              pgSubscriptionId,
              pgPaymentId,
              pgSignature,
            }),
            interval,
          );
        } else if (
          subscriptionSuccessfullStatus(
            response?.data?.data?.data?.subscriptionStatus,
          )
        ) {
          getActivePlan();
          getAvailableSubscriptions({
            setIsLoading: () => {},
            setAvailableSubscriptions: () => {},
            setOpen: () => {},
          });
          setCurrentStep(paymentComponentBoxStrings.paymentSuccess);
          upgradeSuccess({
            days_remaining: timeLeftFromNow(activeSubscription?.endsOn),
          });
        }
      },
      onError: (err) => {},
      finally: () => {},
    });
  };

  return {
    getActivePlan,
    getUpcomingPlan,
    getAvailableSubscriptions,
    initSubscription,
    cancelSubscription,
    isSubscriptionSuccessfull,
    getUserBills,
    autoActivateFreePlan,
    isUpgradeAvailable,
    getUpdatePaymentMethodLink,
  };
};

export default usePaymentApi;
