export enum PaymentDetailsType {
  ORDER = "ORDER",
  SUBSCRIPTION = "SUBSCRIPTION",
  FREE = "FREE",
}

export enum PaymentGateway {
  RAZOR_PAY = "RAZOR_PAY",
  LEMON_SQUEEZY = "LEMON_SQUEEZY",
}

export enum SubscriptionStatus {
  CREATED = "CREATED",
  AUTHENTICATED = "AUTHENTICATED",
  ACTIVE = "ACTIVE",
  PENDING = "PENDING",
  PAUSED = "PAUSED",
  HALTED = "HALTED",
  CANCELLED = "CANCELLED",
  COMPLETED = "COMPLETED",
  EXPIRED = "EXPIRED",
}

export enum UserSubscriptionCode {
  FREE_TRIAL,
  UPGRADE_ADJUSTMENT,
  PAID,
  UPGRADE_ADJUSTMENT_AND_PAID,
}

export type SubscriptionFeature = {
  featureId: string;
  featureKey: string;
  usageLimit: number;
  createdOn: string;
  updatedOn: string;
};

export type PricingPlan = {
  planId: string;
  planGroupId: string;
  name: string;
  validationPeriodType: string;
  interval: number;
  billingCount: number;
  trialPeriod: number;
  planType: string;
  countryCode: string;
  currencyCode: string;
  price: number;
  discountedPrice: number;
  priceConversionFactor: number;
  isActive: boolean;
  isSaver: boolean;
  isRecommended: boolean;
  subscriptionFeatures: SubscriptionFeature[];
  createdOn: string;
  updatedOn: string;
};

export type PaymentSubscription = {
  subscriptionId: string;
  totalCount: number;
  paidCount: number;
  startsOn?: string;
  endsOn?: string;
  endedOn?: string;
  subscriptionStatus: SubscriptionStatus;
  paymentGateway: string;
  pgSubscriptionId?: string;
  pricingPlan: PricingPlan;
  createdOn: string;
  updatedOn: string;
};

export type Payment = {
  paymentId: string;
  amount: number;
  currency: string;
  status: string;
  pgPaymentId: string;
  paymentGateway: string;
  sourceSubscriptionPlanType: string;
  paymentSubscription: PaymentSubscription;
  createdOn: string;
  updatedOn: string;
};

export type UserSubscription = {
  isCancelled: Boolean;
  startsOn: string;
  endsOn: string;
  reasonCode: UserSubscriptionCode;
  payment?: Payment;
  pricingPlan: PricingPlan;
  createdOn: string;
  updatedOn: string;
};

export type PaymentDetails = {
  type: PaymentDetailsType;
  paymentGateway: PaymentGateway;
  timeoutInSec: number;
  subscription?: PaymentSubscription;
  userSubscription?: UserSubscription;
  checkoutUrl?: string;
};

export type PaymentGatewayDetails = {
  key?: string;
  paymentGateway: string;
};

export type BusinessDetails = {
  name: string;
  logo: string;
  description: string;
};

export type InitPaymentResponse = {
  paymentDetails: PaymentDetails;
  businessDetails: BusinessDetails;
  paymentGatewayDetails?: PaymentGatewayDetails;
};

export type ConfirmPaymentResponse = {
  poll: boolean;
  interval?: number;
  data?: PaymentSubscription;
};
