const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const subscriptionSuccessfullStatus = (status) => {
  const statusValues = ["AUTHENTICATED", "ACTIVE"];
  return statusValues.includes(status);
};

export const paymentFailedStatus = (status) => {
  const statusValues = [
    "PENDING",
    "PAUSED",
    "HALTED",
    "CANCELLED",
    "COMPLETED",
    "EXPIRED",
  ];
  return statusValues.includes(status);
};

export const isSubscriptionCancelled = (status) => {
  const statusValues = ["CANCELLED", "CREATED", "COMPLETED", "EXPIRED"];
  return statusValues.includes(status);
};

export const changeDateFormat = (currentDate) => {
  const date = new Date(currentDate);

  const day = date.getUTCDate();
  const month = months[date.getUTCMonth()];
  const year = date.getUTCFullYear();

  return `${day} ${month} ${year}`;
};

export const getLastBilledValue = (billingDate, payment) => {
  if (payment) {
    const date = new Date(billingDate);

    const day = date.getUTCDate();
    const month = months[date.getUTCMonth()];
    const year = date.getUTCFullYear();

    return `${day} ${month} ${year}`;
  } else {
    return "-";
  }
};

export const isActiveSubscriptionFree = (reasonCode) => {
  const statusValues = ["FREE_TRIAL"];
  return statusValues.includes(reasonCode);
};

export const isUpgradeOptionAvailable = (currentPlan) => {
  const planValues = ["Basic - Yearly"];
  return !planValues.includes(currentPlan);
};

export const checkIfActiveSubscriptionIsFree = (price) => {
  return price === 0;
};

export const countTotalDays = (validationPeriodType, interval) => {
  const daysMapping = {
    DAY: 1,
    WEEK: 7,
    MONTH: 30,
    QUARTER: 90,
    YEAR: 365,
  };
  return daysMapping[validationPeriodType] * interval;
};

export const showDiscountPercentage = (price, discountedPrice) => {
  return `${Math.round(((price - discountedPrice) / price) * 100)}% off`;
};

export const showmaximumDiscountPercentageInSuggestedPlans = (plans) => {
  let maxDiscount = 0;
  plans.forEach((plan) => {
    const discount = Math.round(
      ((plan.price - plan.discountedPrice) / plan.price) * 100
    );
    if (discount > maxDiscount) {
      maxDiscount = discount;
    }
  });
  return maxDiscount;
};

export const getBillingCycleValue = (validationPeriodType, interval) => {
  const periodTypeMapping = {
    DAY: "Daily",
    WEEK: "Weekly",
    MONTH: "Monthly",
    QUARTER: "Quarterly",
    YEAR: "Yearly",
  };
  if (interval > 1) {
    return `Every ${interval} ${validationPeriodType.toLowerCase()}s`;
  } else {
    return periodTypeMapping[validationPeriodType];
  }
};

export const getUpcomingPlanStatus = (startDate) => {
  return `Active from ${getLastBilledValue(startDate, "payment")}`;
};

export const ActivateFreePlanIfAvailable = ({
  response,
  setIsPaymentModalOpen,
  initSubscription,
  showAlert,
  checkingFreePlan,
}) => {
  let flag = false;
  console.log("check free", response?.data?.data);
  response?.data?.data?.forEach((plan) => {
    if (checkIfActiveSubscriptionIsFree(plan?.discountedPrice)) {
      flag = true;
      setIsPaymentModalOpen(false);
      const totalDays = countTotalDays(
        plan?.validationPeriodType,
        plan?.interval
      );
      initSubscription({
        planId: plan?.planId,
        setInitializingPayment: () => {},
        displayRazorpay: () => {},
        setCurrentStep: () => {
          showAlert(totalDays);
        },
      });
    }
  });
  if (!flag) {
    checkingFreePlan(false);
  }
};

export const getMessageToShowOnPlanCard = (
  activeSubscription,
  lastSubscription,
  currentSubscription
) => {
  if (activeSubscription) {
    return "";
  }
  if (
    !activeSubscription &&
    checkIfActiveSubscriptionIsFree(
      lastSubscription?.pricingPlan?.discountedPrice
    )
  ) {
    return "Your Free Trial has ended. Upgrade your plan today";
  } else {
    return "No active plan. Subscribe to continue using services.";
  }
};

export const isPreviousSubscriptionFree = (lastSubscription) => {
  return lastSubscription?.pricingPlan?.discountedPrice === 0;
};

export const savingOnUpgrade = (currentPlan, suggestedPlans) => {
  const maximumPriceInSuggestedPlans = Math.max(
    ...suggestedPlans.map((plan) => plan?.discountedPrice)
  );
  const currentPlanPrice =
    currentPlan?.payment?.paymentSubscription?.pricingPlan?.discountedPrice;
  const discount =
    (currentPlanPrice * 12 - maximumPriceInSuggestedPlans) /
    suggestedPlans[0]?.priceConversionFactor;
  return parseInt(discount);
};

export const getValueText = (interval, amount, intervalPeriod) => {
  const periodTypeMapping = {
    DAY: "daily",
    WEEK: "weekly",
    MONTH: "monthly",
    QUARTER: "quarterly",
    YEAR: "yearly",
  };
  const monthlyMultiplier = {
    DAY: 30,
    WEEK: 4,
    MONTH: 1,
    QUARTER: 1 / 3,
    YEAR: 1 / 12,
  };
  // return `${parseInt(
  //   (amount * monthlyMultiplier[interval]) / intervalPeriod
  // )} per ${intervalPeriod > 1 ? intervalPeriod : ""} month build ${
  //   periodTypeMapping[interval]
  // }`;
  return {
    value: parseInt((amount * monthlyMultiplier[interval]) / intervalPeriod),
    text: `billed ${periodTypeMapping[interval]}`,
  };
};
