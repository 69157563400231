export const trustmarkers = [
  "A great tool for personal branding.",
  "It's like having a superhero assistant for LinkedIn.",
  "ideas which it suggests are mind-blowing.",
  "I already highly recommend it to others.",
  "I love using Reachifyme.",
  "The tool is super amazing.",
];

export const cancelSubscriptionModalStrings = {
  description: "Are you sure you want to cancel your subscription?",
  confirm: "Yes, cancel",
  cancel: "Back",
};

export const seeMoreStrings = {
  seeMore: "•••",
  cancelSubscription: "Cancel Subscription",
  updatePaymentMethod: "Update Payment Method",
};

export const currentPlanStrings = {
  currentPlan: "Current Plan",
  noActivePlan: "No Subscription",
  viewInvoices: "View Invoices",
  plan: "Plan",
  status: "Status",
  active: "Active",
  billingCycle: "Billing Cycle",
  lastBilled: "Last Billed",
  normal: "NORMAL",
};

export const pageOverlayLockStrings = {
  buttonName: "Upgrade to continue",
};

export const paymentComponentBoxStrings = {
  paymentCard: "paymentCard",
  paymentVerifying: "paymentVerifying",
  paymentSuccess: "paymentSuccess",
  paymentFailed: "paymentFailed",
};

export const paymentComponentBoxTypeStrings = {
  modal: "modal",
  static: "static",
};

export const paymentStatusModalStrings = {
  success: {
    value: "success",
    title: "Congratulations! You are subscribed to ReachifyMe",
    description:
      "Let’s start your journey of personal branding with ReachifyMe",
    buttonText: "Create a post",
  },
  failed: {
    value: "failed",
    title: "Payment failed",
    description: "Please try again",
    buttonText: "Cancel",
  },
};
