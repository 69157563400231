import React, { useEffect, useRef, useState } from "react";
import usePaymentApi from "../../paymentApi";
import { useDispatch, useSelector } from "react-redux";
import Buttons from "../../../custombuttons/Buttons";
import {
  getValueText,
  showDiscountPercentage,
  showmaximumDiscountPercentageInSuggestedPlans,
} from "../../../../utils/payment";
import { LoaderSkeleton } from "../PaymentPlanCard";
import { proceedToPaymentClick } from "../../../../utils/paymentEvents";
import PaymentVerifyingModal from "../PaymentVerifyingModal";
import { setOpenTimer } from "../../../../reducers/slices/paymentOfferTimerSlice";
import { usePayment } from "../../provider/usePayment";
import { PaymentStep } from "../../PaymentComponentBox";
const planCardStyle = {
  notSelected: {
    border: "1px solid #6366F11A",
    background: "#ECECFF",
    color: "#0C1620",
  },
  selected: {
    border: "1px solid #6366F1",
    background: "#6366F1",
    color: "#FFFFFF",
    boxShadow: "8px 6px 6px 0px #0008FF0F",
  },
};
const PricingLandingPaymentCard = ({
  setCurrentStep,
  displayRazorpay,
  paymentComponentType,
  setOpen,
  setIsRazorpayModalOpen,
  isRazorpayModalOpen,
  currentStep,
  availableSubscriptions,
  userId,
  initializingPayment,
  setInitializingPayment,
  paymentButtonDisabled,
  setPaymentButtonDisabled,
  initSubscriptionHandler,
  getAvailableSubscriptions,
  isVerifyingPayment,
  setIsVerifyingPayment,
  isMobile,
}) => {
  const { step } = usePayment();
  const dispatch = useDispatch();
  const testimonialsRef = useRef(null);
  const activeSubscription = useSelector(
    (state) => state.payment.activeSubscription
  );
  const openTimer = useSelector((state) => state.paymentOfferTimer.openTimer);
  const currentTime = new Date().getTime();
  const actualTimeSpentWhileModalOpen = currentTime - openTimer;
  var sec = Math.floor((actualTimeSpentWhileModalOpen / 1000) % 60);
  var min = Math.floor((actualTimeSpentWhileModalOpen / (1000 * 60)) % 60);
  if (min > 30) {
    const today = new Date().getTime();
    dispatch(setOpenTimer(today));
    min = 30;
    sec = 0;
  } else {
    min = 30 - min;
  }
  sec = 59 - sec;
  const [minutes, setMinutes] = useState(min || 30);
  const [seconds, setSeconds] = useState(sec || 59);
  const [isAvailableSubscriptionsLoading, setIsAvailableSubscriptionsLoading] =
    useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  useEffect(() => {
    if (!availableSubscriptions) {
      getAvailableSubscriptions({
        setIsLoading: setIsAvailableSubscriptionsLoading,
        setAvailableSubscriptions: () => {},
        setOpen,
      });
    }
  }, [activeSubscription]);

  useEffect(() => {
    if (availableSubscriptions && availableSubscriptions.length > 0) {
      setSelectedPlan(
        availableSubscriptions[availableSubscriptions.length - 1]
      );
    }
  }, [availableSubscriptions]);
  useEffect(() => {
    const handleBeforeUnload = (event) => {};
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const selectedPlanHandler = (plan) => {
    setSelectedPlan(plan);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const slideTestimonials = (direction) => {
    const testimonials = testimonialsRef.current;
    const testimonialsWidth = testimonials.offsetWidth;
    const scrollWidth = testimonials.scrollWidth;
    const scrollLeft = testimonials.scrollLeft;
    const scrollRight = scrollWidth - scrollLeft - testimonialsWidth;
    if (direction === "right") {
      if (scrollRight > testimonialsWidth) {
        testimonials.scrollLeft += testimonialsWidth;
      } else {
        testimonials.scrollLeft += scrollRight;
      }
    } else {
      if (scrollLeft > testimonialsWidth) {
        testimonials.scrollLeft -= testimonialsWidth;
      } else {
        testimonials.scrollLeft -= scrollLeft;
      }
    }
  };

  const getTime = () => {
    if (minutes < 0) {
      const today = new Date().getTime();
      dispatch(setOpenTimer(today));
      setMinutes(30);
      setSeconds(0);
      return;
    }
    if (minutes === 0 && seconds === 0) {
      const today = new Date().getTime();
      dispatch(setOpenTimer(today));
      setMinutes(30);
      setSeconds(0);
      return;
    }
    if (seconds === 0) {
      setMinutes((prev) => prev - 1);
      setSeconds(59);
      return;
    }
    setSeconds((prev) => prev - 1);
  };
  useEffect(() => {
    const interval = setInterval(() => getTime(), 1000);

    return () => clearInterval(interval);
  }, [minutes, seconds]);

  return (
    <div className="payment-section">
      <div className="offer-text">
        Get{" "}
        <span className="percent-off-text">
          {showmaximumDiscountPercentageInSuggestedPlans(
            availableSubscriptions ?? []
          )}
          % off
        </span>{" "}
        - Just for you
      </div>
      <div className="payment-plan-card-timer">
        <div className="payment-plan-card_body_up_timer_time">
          <div className="payment-plan-card_body_up_timer_time_value">00</div>:
          <div className="payment-plan-card_body_up_timer_time_value">
            {minutes < 10 ? `0${minutes}` : minutes}
          </div>
          :
          <div className="payment-plan-card_body_up_timer_time_value">
            {seconds < 10 ? `0${seconds}` : seconds}
          </div>
        </div>
        <div className="hurry-up-text">Limited Offer Hurry Up</div>
      </div>
      <div className="payment-plan-card_body_pricing_landing">
        {isAvailableSubscriptionsLoading ? (
          <>
            <LoaderSkeleton />
            <LoaderSkeleton />
          </>
        ) : (
          availableSubscriptions &&
          availableSubscriptions.map((subscription, index) => {
            const words = subscription.name.trim().split(" ");
            const name = words[words.length - 1];
            const nf = new Intl.NumberFormat("en-UK", {
              style: "currency",
              currency: subscription.currencyCode,
              currencyDisplay: "narrowSymbol",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
              trailingZeroDisplay: "stripIfInteger",
            });
            return (
              <div
                className="plan-card"
                style={
                  selectedPlan === subscription || isHovered
                    ? planCardStyle.selected
                    : planCardStyle.notSelected
                }
                key={index}
                onMouseEnter={() => {
                  if (selectedPlan === subscription || initializingPayment) {
                    return;
                  }
                  setIsHovered(true);
                  selectedPlanHandler(subscription);
                  setIsHovered(false);
                }}
                onMouseLeave={() => {
                  if (initializingPayment) {
                    return;
                  }
                  setIsHovered(false);
                }}
                onClick={() => {
                  if (initializingPayment) {
                    return;
                  }
                  setIsHovered(false);
                  selectedPlanHandler(subscription);
                }}
              >
                <div className="plan-card_inner_box">
                  <div>
                    <div className="plan-card_title_box">
                      {subscription.name === "Yearly" && (
                        <div>
                          <img src="image/payment/star.svg" alt="star" />
                        </div>
                      )}
                      <div
                        className="plan-card_name"
                        style={{
                          color:
                            selectedPlan === subscription || isHovered
                              ? planCardStyle.selected.color
                              : planCardStyle.notSelected.color,
                        }}
                      >
                        {name} Plan
                      </div>
                      <div className="plan-card_discount-box">
                        <div
                          className="plan-card_discount-left-bookmark"
                          style={{
                            borderLeft:
                              selectedPlan === subscription || isHovered
                                ? `9px solid ${planCardStyle.selected.background}`
                                : `9px solid ${planCardStyle.notSelected.background}`,
                          }}
                        ></div>
                        <div className="plan-card_discount_value">
                          {subscription.price - subscription.discountedPrice > 0
                            ? showDiscountPercentage(
                                subscription.price,
                                subscription.discountedPrice
                              )
                            : " "}
                        </div>
                        <div
                          className="plan-card_discount-right-bookmark"
                          style={{
                            borderRight:
                              selectedPlan === subscription || isHovered
                                ? `9px solid ${planCardStyle.selected.background}`
                                : `9px solid ${planCardStyle.notSelected.background}`,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="plan-card_prices">
                    <div>
                      <div className="price_box">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "flex-end",
                          }}
                        >
                          <div
                            className="offered-price"
                            style={{
                              color:
                                selectedPlan === subscription || isHovered
                                  ? "#FFFFFF"
                                  : "#3F3B31",
                            }}
                          >
                            {nf.format(
                              getValueText(
                                subscription.validationPeriodType,
                                subscription.discountedPrice /
                                  subscription.priceConversionFactor,
                                subscription.interval
                              ).value
                            )}
                          </div>
                          <div
                            className="offered-price-text"
                            style={{
                              color:
                                selectedPlan === subscription || isHovered
                                  ? "#FFFFFF"
                                  : "#3F3B31",
                            }}
                          >
                            /month{" "}
                          </div>
                        </div>
                      </div>
                      <div
                        className="price-label"
                        style={{
                          color:
                            selectedPlan === subscription || isHovered
                              ? "#EDEDED"
                              : "#3F3B31",
                        }}
                      >
                        {
                          getValueText(
                            subscription.validationPeriodType,
                            subscription.discountedPrice /
                              subscription.priceConversionFactor,
                            subscription.interval
                          ).text
                        }
                        {/* per{" "}
                        {subscription.interval > 1 ? subscription.interval : ""}{" "}
                        {subscription.validationPeriodType.toLowerCase()} */}
                      </div>
                    </div>
                    <div className="plan-card-discount-actual-price">
                      <div
                        className="actual-price"
                        style={{
                          color:
                            selectedPlan === subscription || isHovered
                              ? "#D5D5D5"
                              : "#808080",
                        }}
                      >
                        {/* {nf.format(
                            subscription.price /
                              subscription.priceConversionFactor
                          )} */}
                        {nf.format(
                          getValueText(
                            subscription.validationPeriodType,
                            subscription.price /
                              subscription.priceConversionFactor,
                            subscription.interval
                          ).value
                        )}
                      </div>
                    </div>

                    <div
                      style={{
                        marginLeft: "auto",
                        display: isMobile ? "initial" : "none",
                      }}
                    >
                      <Buttons
                        key={index}
                        variant={
                          step === PaymentStep.INITIATION &&
                          selectedPlan === subscription
                            ? "loader"
                            : !paymentButtonDisabled
                              ? "gradient"
                              : "disabled"
                        }
                        text={"Pay Now "}
                        styles={{
                          boxShadow: "none",

                          background:
                            selectedPlan === subscription || isHovered
                              ? "linear-gradient(91.09deg, #FAE086 8.54%, #F3B453 92.32%)"
                              : "#ECECFF",
                          border:
                            selectedPlan === subscription || isHovered
                              ? "2px solid #F3B453"
                              : "1px solid #111111",
                          borderImageSorce:
                            selectedPlan === subscription || isHovered
                              ? "linear-gradient(91.09deg, #FAE086 8.54%, #F3B453 92.32%)"
                              : "#111111",
                          // height: "50px",
                          height: "38px",
                          padding: "10px 30px",
                          width: isMobile ? "100%" : "100%",
                          minWidth: "115px",
                          // fontSize: "16px",
                          fontSize: "12px",
                          fontWeight: "500",
                        }}
                        clickHandler={() => {
                          initSubscriptionHandler(subscription);
                        }}
                      />
                    </div>
                  </div>
                </div>
                {subscription.validationPeriodType === "YEAR" && (
                  <div
                    className="recommended-card-highlight-feature-box"
                    style={{ marginTop: "8px" }}
                  >
                    <div
                      className="recommended-card-highlight-feature"
                      style={{ justifyContent: "flex-start" }}
                    >
                      <div
                        className="recommended-feature-text"
                        style={{
                          color:
                            selectedPlan === subscription
                              ? "#FFFFFF"
                              : "#3F3B31",
                          fontSize: "12px",
                          width: isMobile && "60vw",
                        }}
                      >
                        + Free access to{" "}
                        {isMobile &&
                          " Onboarding call, LinkedIn growth guide & Community"}
                      </div>
                    </div>
                    <div
                      className="recommended-feature-text"
                      style={{
                        color:
                          selectedPlan === subscription ? "#FFFFFF" : "#3F3B31",
                        textAlign: "start",
                        fontSize: "12px",
                      }}
                    >
                      {!isMobile &&
                        "Onboarding call, LinkedIn growth guide & Community"}
                    </div>
                  </div>
                )}
                <div className="payment-button-container">
                  <Buttons
                    key={index}
                    variant={
                      step === PaymentStep.INITIATION &&
                      selectedPlan === subscription
                        ? "loader"
                        : !paymentButtonDisabled
                          ? "gradient"
                          : "disabled"
                    }
                    text={"Pay Now "}
                    styles={{
                      boxShadow: "none",
                      background:
                        selectedPlan === subscription || isHovered
                          ? "linear-gradient(91.09deg, #FAE086 8.54%, #F3B453 92.32%)"
                          : "#ECECFF",
                      border:
                        selectedPlan === subscription || isHovered
                          ? "2px solid #F3B453"
                          : "1px solid #111111",
                      borderImageSorce:
                        selectedPlan === subscription || isHovered
                          ? "linear-gradient(91.09deg, #FAE086 8.54%, #F3B453 92.32%)"
                          : "#111111",
                      // height: "50px",
                      height: "44px",

                      width: isMobile ? "100%" : "100%",
                      minWidth: "115px",
                      // fontSize: "16px",
                      fontSize: "16px",
                      fontWeight: "500",
                    }}
                    clickHandler={() => {
                      initSubscriptionHandler(subscription);
                    }}
                  />
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default PricingLandingPaymentCard;
