import React, { useEffect, useState } from "react";
import PricingLandingDescription from "./PricingLandingComponents/PricingLandingDescription";
import "./pricingLanding.scss";
import PricingLandingPayment from "./PricingLandingComponents/PricingLandingPayment";
import usePaymentApi from "../paymentApi";
import { proceedToPaymentClick } from "../../../utils/paymentEvents";
import { useDispatch, useSelector } from "react-redux";
import { usePayment } from "../provider/usePayment";

const PricingLanding = ({
  msg,
  setCurrentStep,
  setOpen,
  paymentComponentType,
  useCase,
  currentStep,
  isRazorpayModalOpen,
  setIsRazorpayModalOpen,
  displayRazorpay,
  initSubscriptionHandler,
}) => {
  const { step } = usePayment();
  const dispatch = useDispatch();
  const { getAvailableSubscriptions, initSubscription } = usePaymentApi();
  const [initializingPayment, setInitializingPayment] = useState(false);
  const [isVerifyingPayment, setIsVerifyingPayment] = useState(false);
  const [paymentButtonDisabled, setPaymentButtonDisabled] = useState(false);
  const isMobile = useSelector((state) => state.device.isMobileDevice);
  const availableSubscriptions = useSelector(
    (state) => state.payment.suggestedPlans
  );
  const userId = useSelector((state) => state.userData?.userId);
  const today = new Date().getTime();
  useEffect(() => {
    console.log("payment step", step);
  }, [step]);

  return (
    <div className="pricing-landing-main-container">
      {/* left_conatiner */}
      <PricingLandingDescription
        availableSubscriptions={availableSubscriptions}
        setCurrentStep={setCurrentStep}
        setOpen={setOpen}
        displayRazorpay={displayRazorpay}
        paymentComponentType={paymentComponentType}
        isRazorpayModalOpen={isRazorpayModalOpen}
        setIsRazorpayModalOpen={setIsRazorpayModalOpen}
        currentStep={currentStep}
        initSubscriptionHandler={initSubscriptionHandler}
        initializingPayment={initializingPayment}
        paymentButtonDisabled={paymentButtonDisabled}
        isMobile={isMobile}
      />
      {/* right_container */}
      <PricingLandingPayment
        setCurrentStep={setCurrentStep}
        setOpen={setOpen}
        displayRazorpay={displayRazorpay}
        paymentComponentType={paymentComponentType}
        isRazorpayModalOpen={isRazorpayModalOpen}
        setIsRazorpayModalOpen={setIsRazorpayModalOpen}
        currentStep={currentStep}
        availableSubscriptions={availableSubscriptions}
        userId={userId}
        initializingPayment={initializingPayment}
        setInitializingPayment={setInitializingPayment}
        paymentButtonDisabled={paymentButtonDisabled}
        setPaymentButtonDisabled={setPaymentButtonDisabled}
        initSubscriptionHandler={initSubscriptionHandler}
        getAvailableSubscriptions={getAvailableSubscriptions}
        isVerifyingPayment={isVerifyingPayment}
        setIsVerifyingPayment={setIsVerifyingPayment}
        isMobile={isMobile}
      />
    </div>
  );
};

export default PricingLanding;
