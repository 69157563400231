export const DateFormatter = ({ timestamp }) => {
  const date = new Date(timestamp);
  date.setHours(date.getHours() + 5); // Add 5 hours for IST
  date.setMinutes(date.getMinutes() + 30); // Add 30 minutes for IST

  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const dayOfWeek = daysOfWeek[date.getUTCDay()];

  const day = date.getUTCDate();
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const month = months[date.getUTCMonth()];

  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const amOrPm = hours >= 12 ? "PM" : "AM";

  // Convert 24-hour time to 12-hour time
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;

  const formattedDate = `${dayOfWeek}, ${day} ${month} at ${formattedHours}:${minutes
    .toString()
    .padStart(2, "0")} ${amOrPm}`;
  return formattedDate;
};

export const DateFormatterForPost = ({ timestamp }) => {
  const dateString = timestamp;
  console.log(dateString, "dateString");
  const date = new Date(dateString);
  const options = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  const formattedDate = date.toLocaleDateString("en-US", options);
  console.log(formattedDate, "date");
  return formattedDate;
};

export const DateFormatterforSchedule = ({ timeStamp }) => {
  const date = new Date(timeStamp);
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Month is 0-based, so we add 1 and pad with leading zeros
  const day = String(date.getUTCDate()).padStart(2, "0");
  const hours = String(date.getUTCHours()).padStart(2, "0");
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");
  const seconds = String(date.getUTCSeconds()).padStart(2, "0");

  // Create the formatted string
  const formattedTimestamp = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}+5:30`;
  return formattedTimestamp;
};

export const convertToRequiredFormat = (inputDate) => {
  const dateParts = inputDate.split("/");
  const month = parseInt(dateParts[0], 10);
  const day = parseInt(dateParts[1], 10);
  const year = parseInt(dateParts[2], 10);

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const formattedDate = new Date(year, month - 1, day);
  const dayOfWeek = daysOfWeek[formattedDate.getDay()];
  const monthName = months[formattedDate.getMonth()];
  const formattedDateString = `${dayOfWeek}, ${day} ${monthName} ${year}`;

  return formattedDateString;
};

export const timeLeftFromNowInDays = (futureDate, currentDate) => {
  const now = new Date(currentDate);

  const future = new Date(futureDate);

  const diffTime = future - now;

  // Convert the difference from milliseconds to days
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays > 1 ? diffDays : 1;
};

export const timeLeftFromNow = (futureDate, currentDate) => {
  const now = new Date(currentDate);

  const future = new Date(futureDate);

  const diffTime = future - now;

  // Convert the difference from milliseconds to days
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  if (diffDays <= 0) {
    return {
      unit: "",
      value: "Expired",
      color: "#962222",
    };
  }
  if (diffDays > 0) {
    return {
      unit: "days",
      value: diffDays,
      color: diffDays <= 1 ? "#962222" : "#121212",
    };
  }

  const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));
  if (diffHours > 0) {
    return { unit: "hours", value: diffHours, color: "#962222" };
  }

  const diffMinutes = Math.ceil(diffTime / (1000 * 60));
  if (diffMinutes > 0) {
    return { unit: "minutes", value: diffMinutes, color: "#962222" };
  }
  return {
    unit: "minute",
    value: 0,
    color: "#962222",
  };
};

export const planExpired = (endDate, currentTime) => {
  const now = new Date(currentTime);

  const end = new Date(endDate);

  const diffTime = end - now;

  // Return the number of days remaining
  return diffTime <= 0;
};

export const timeBetweenStartandDate = (startDate, endDate) => {
  console.log(startDate, endDate, "start and end date");
  const start = new Date(startDate);
  const end = new Date(endDate);

  const diffTime = end - start;

  // Convert the difference from milliseconds to days
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  // Return the number of days remaining
  return diffDays;
};
