import React from "react";
import WhyReachifyMeCard from "./WhyReachifyMeCard";
import { useSelector } from "react-redux";
export function WhyReachifyMe({ whyReachifyMeCardContent,reachifyMePrice,agencyPriceMonthly,agencyPriceYearly }) {
  const isMobile = useSelector((state) => state.device?.isMobileDevice);

  return (
    <div className="whyReachifyMe-main-container">
      <div className="whyReachifyMe-heading">
        {isMobile ? "Why Reachifyme" : "What difference does ReachifyMe make?"}
      </div>
      <div className="whyReachifyMe-card-grid-container">
        {whyReachifyMeCardContent.map((card, index) => (
          <WhyReachifyMeCard
            key={index}
            {...card}
            reachifyMePrice={
              card.cardBackground === "#FFFCF3" ? reachifyMePrice : null
            }
            agencyPriceMonthly={
              card.highlightValue === "30 k" ? agencyPriceMonthly : null
            }
            agencyPriceYearly={
              card.highlightValue === "30 k" ? agencyPriceYearly : null
            }
          />
        ))}
      </div>
    </div>
  );
}
