import Lottie from "lottie-react";
import React, { useEffect, useState } from "react";
import paymentLoader from "../assets/paymentLoader.json";
import Spinner from "../../Spinner/Spinner";
import CustomModal from "../../Modal/CustomModal";

const PaymentVerifyingModal = () => {
  const messages = [
    "Fetching details of your subscription",
    "Confirming payment details",
    "Activating your subscription",
  ];

  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  useEffect(() => {
    if (currentMessageIndex < messages.length - 1) {
      const timer = setTimeout(() => {
        setCurrentMessageIndex(currentMessageIndex + 1);
      }, 22000); // 22 seconds

      return () => clearTimeout(timer);
    }
  }, [currentMessageIndex, messages.length]);

  return (
    <CustomModal
      open={true}
      setOpen={() => {}}
      maxWidth={"400px"}
      width={"400px"}
      showCancelButton={false}
    >
      <div
        style={{
          width: "100%",
          minHeight: "360px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <div style={{ height: "180px", width: "180px" }}>
          <Spinner />
        </div>
        <div style={{ fontSize: "14px" }}>
          This might take a while, Stay with us while we activate your
          subscription
        </div>
        <div style={{ fontSize: "16px", fontWeight: "600" }}>
          {messages[currentMessageIndex]}
        </div>
      </div>
    </CustomModal>
  );
};

export default PaymentVerifyingModal;
