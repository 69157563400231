import api from "../axios/api";
import { AxiosResponse, Method } from "axios";
import { ApiResponse } from "../types/api";

const request = <I, O>(
  method: Method,
  url: string,
  data?: I,
): Promise<O | undefined> => {
  return new Promise<O | undefined>((resolve, reject) => {
    api
      .request({ method: method, url: url, data: data })
      .then((response: AxiosResponse<ApiResponse<O>>) => {
        resolve(response.data.data);
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          reject(error.response.data.error);
        } else {
          reject(error);
        }
      });
  });
};

export default request;
