import { useSelector } from "react-redux";
import "../paymentComponents.scss";

// icon urls
const STARS_URL = "/image/pricing/stars.svg";
const THUNDERBOLT_URL = "/image/pricing/thunderbolt.svg";
const MEME_GENERATION_URL = "/image/pricing/meme-generation.svg";
const PROFILE_PERSONALIZATION_URL =
  "/image/pricing/profile-personalization.svg";
const NICE_IDEAS_URL = "/image/pricing/nice-ideas.svg";
const IMAGE_LIBRARIES_URL = "/image/pricing/image-libraries.svg";
const ADD_OPINION_URL = "/image/pricing/add-opinion.svg";
const CURATED_POSTS_URL = "/image/pricing/curated-post.svg";
const DYNAMIC_CONTENT_URL = "/image/pricing/dynamic-content.svg";
const TRENDING_POST_URL = "/image/pricing/trending-post.svg";
const NO_RESEARCH_NEEDED_URL = "/image/pricing/no-research.svg";
const DAY_SCHEDULING_URL = "/image/pricing/day-scheduling.svg";
const CREATIVE_TEXT_EDITS_URL = "/image/pricing/creative-text.svg";
const SUPPORT_URL = "/image/pricing/support.svg";
const SAVE_DRAFTS_URL = "/image/pricing/save-drafts.svg";
const CAROUSEL_INTEGRATION_URL = "/image/pricing/carousel-integration.svg";
const WHYREACHIFYME_CARD_1_URL = "/image/pricing/do-it-yourself.svg";
const WHYREACHIFYME_CARD_2_URL = "/image/pricing/hire-an-agency.svg";
const WHYREACHIFYME_CARD_3_URL = "/image/pricing/join-reachifyme.svg";
const PROFILEIMGTESTIMONIALS_URL = "/image/pricing/profile-image.svg";
const PROFILEIMGTESTIMONIALS_2_URL="/image/pricing/profile-image-2.svg";
const WHYREACHIFYME_CARD_3_SUB_IMG_URL = "/image/pricing/correct-tick.svg";
const POST_IMPRESSION = "/image/pricing/post-impression.svg";
const FOLLOWERS = "/image/pricing/followers.svg";
const SEARCH_APPEAR = "/image/pricing/search-appear.svg";
const PROFILE_VIEW = "/image/pricing/profile-view.svg";
const WHYREACHIFYME_CARD_1_SUB_IMG_URL = "/image/pricing/wrong-cross.svg";


export const whyReachifyMeCardContent = [
  {
    border: "1px solid #DFDFDF",
    cardBackground: "#ffffff",
    imgBackground: "#6366F10D",
    imgBorder: false,
    imgShadow: false,
    img: WHYREACHIFYME_CARD_1_URL,
    title: "If you Do it Yourself…",
    alt_img_text: "If you Do it Yourself Image",
    highlightValue: "2 hrs",
    highlightValueSub: "Daily",
    description: "Huge effort and research. 700+ hours a Year.",
    summaryBoxBackground: "#FF0F0F0D",
    summaryBoxTextColor: "#FF0F0F",
    summaryBoxIconImg: WHYREACHIFYME_CARD_1_SUB_IMG_URL,
    summaryBoxIconAltText: "Wrong Cross Image",
    summaryBox: "Too much time required",
  },
  {
    border: "1px solid #DFDFDF",
    cardBackground: "#ffffff",
    imgBackground: "#6366F10D",
    imgBorder: false,
    imgShadow: false,
    img: WHYREACHIFYME_CARD_2_URL,
    title: "If you Hire an Agency…",
    alt_img_text: "If you Hire an Agency Image",
    highlightValue: "30 k",
    highlightValueSub: "/Month",
    description: "= INR 4 Lakhs per year and few hours monthly.",
    summaryBoxBackground: "#FF0F0F0D",
    summaryBoxTextColor: "#FF0F0F",
    summaryBoxIconImg: WHYREACHIFYME_CARD_1_SUB_IMG_URL,
    summaryBoxIconAltText: "Wrong Cross Image",
    summaryBox: "High on Budget",
  },
  {
    border: "1px solid #E9B239",
    cardBackground: "#FFFCF3",
    imgBackground: "#FFFFFF",
    imgBorder: true,
    imgShadow: true,
    img: WHYREACHIFYME_CARD_3_URL,
    title: "Join Reachifyme",
    alt_img_text: "Join Reachifyme Image",
    highlightValue: "1000",
    highlightValueSub: "/Month",
    description: "Less money and time per day for effective outputs",
    summaryBoxBackground: "#007A000D",
    summaryBoxTextColor: "#0DAE6B",
    summaryBoxIconImg: WHYREACHIFYME_CARD_3_SUB_IMG_URL,
    summaryBoxIconAltText: "Correct Tick Image",
    summaryBox: "Budget and Time Friendly",
  },
];

export const benefitsCardContent = [
  {
    img: STARS_URL,
    title: "Unlimited AI Posts",
    img_alt_text: "Unlimited AI Posts Image",
    description: "Tailored LinkedIn posts, endlessly personalized.",
  },
  {
    img: THUNDERBOLT_URL,
    title: "Trending News Ideas",
    img_alt_text: "Trending News Ideas Image",
    description: "Fresh, daily post ideas on trending topics.",
  },
  {
    img: MEME_GENERATION_URL,
    title: "Meme Generation",
    img_alt_text: "Meme Generation Image",
    description: "Unlimited personalized AI - generated Linkedin posts",
  },
  {
    img: PROFILE_PERSONALIZATION_URL,
    title: "Profile Personalization",
    img_alt_text: "Profile Personalization Image",
    description: "Add personal data to make posts stand out.",
  },
  {
    img: NICE_IDEAS_URL,
    title: "1000+ Nice Ideas",
    img_alt_text: "1000+ Niche Ideas Image",
    description: "Domain-specific content for any industry.",
  },
  {
    img: IMAGE_LIBRARIES_URL,
    title: "Vast Image Libraries",
    img_alt_text: "Vast Image Libraries Image",
    description:
      "Access free images from Google, Unsplash, Pexels, and Pixabay.",
  },
  {
    img: ADD_OPINION_URL,
    title: "Add Your Opinion",
    img_alt_text: "Add Your Opinion Image",
    description: "Share authentic opinions on any topic.",
  },
  {
    img: CURATED_POSTS_URL,
    title: "500+ Curated Posts",
    img_alt_text: "500+ Curated Posts Image",
    description: "High-engagement, ready-to-use content ideas.",
  },
  {
    img: DYNAMIC_CONTENT_URL,
    title: "Dynamic Content",
    img_alt_text: "Dynamic Content Image",
    description: "Hooks, CTAs, short and long-form content ready.",
  },
  {
    img: NO_RESEARCH_NEEDED_URL,
    title: "No Research Needed",
    img_alt_text: "No Research Needed Image",
    description: "We handle research, you focus on posting.",
  },
  {
    img: DAY_SCHEDULING_URL,
    title: "60-Day Scheduling",
    img_alt_text: "60-Day Scheduling Image",
    description: "Schedule posts in advance for up to 60 days.",
  },
  {
    img: CREATIVE_TEXT_EDITS_URL,
    title: "Creative Text Edits",
    img_alt_text: "Creative Text Edits Image",
    description: "Bold, italics, emojis for engaging posts.",
  },
  {
    img: SUPPORT_URL,
    title: "24/7 Support",
    img_alt_text: "24/7 Support Image",
    description: "Always-on customer assistance, any time.",
  },
  {
    img: SAVE_DRAFTS_URL,
    title: "Save as Drafts",
    img_alt_text: "Save as Drafts Image",
    description: "Easily save your content for later use.",
  },
  {
    img: CAROUSEL_INTEGRATION_URL,
    title: "Carousel Generation",
    img_alt_text: "Carousel Generation",
    description: "Generate AI carousels on the go. To increase engagement",
  },
];

export const keyOutcomes = [
  "Effortless Lead Nurturing",
  "Grow your impression",
  "Attract Clients",
];

export const outcomesStats = [
  {
    before: "1,265",
    percentageDown: "10.7%",
    text: "Post Impressions",
    time: "Past 7 days",
  },
  {
    img: POST_IMPRESSION,
  },
  {
    after: "8,092",
    percentageUp: "160.5%",
    text: "Post Impressions",
    time: "Past 7 days",
  },
  {
    before: 65,
    percentageDown: "2.4%",
    text: "Search Appearances",
    time: "Previous week",
  },
  {
    img: SEARCH_APPEAR,
  },
  {
    after: 215,
    percentageUp: "35.2%",
    text: "Search Appearances",
    time: "Previous week",
  },
  {
    before: 320,
    percentageDown: "5.4%",
    text: "Profile Viewers",
    time: "Past 90 days",
  },
  {
    img: PROFILE_VIEW,
  },
  {
    after: "1,057",
    percentageUp: "40.5%",
    text: "Profile Viewers",
    time: "Past 90 days",
  },
  {
    before: "7,160",
    percentageDown: "1.4%",
    text: "Followers",
    time: "Past 7 days",
  },
  {
    img: FOLLOWERS,
  },
  {
    after: "8,026",
    percentageUp: "10.3%",
    text: "Followers",
    time: "Past 7 days",
  },
];

export const testimonialsCardContent = [
  {
    profileImg: PROFILEIMGTESTIMONIALS_URL,
    name: "Arpan Garg",
    role: "Coach",
    country: "India",
    desc: "Loving ReachifyMe! The intuitive, user-friendly interface makes navigation easy. The AI provides spot-on personalized insights, and the engaging content has been invaluable for my growth. Kudos to the team!",
  },
  {
    profileImg: PROFILEIMGTESTIMONIALS_2_URL,
    name: "Anamika",
    role: "Marketing Manager",
    country: "India",
    desc: "Many creators limit themselves to a single niche, but ReachifyMe simplifies ideation and trend analysis, offering a unified dashboard for generating ideas and engaging, trend-driven content for their audience.",
  },
];

export const paymentCardFeaturesContent = [
  {
    text: (
      <div className="features-normal-text">
        Unlimited <span className="features-bold-text">AI-generated</span>{" "}
        LinkedIn posts.
      </div>
    ),
  },
  {
    text: (
      <div className="features-normal-text">
        Daily <span className="features-bold-text">Trending news</span> ideas
        across domain
      </div>
    ),
  },
  {
    text: (
      <div className="features-normal-text">
        Unlimited AI Generated & custom{" "}
        <span className="features-bold-text">Memes</span>
      </div>
    ),
  },
  {
    text: (
      <div className="features-normal-text">
        <span className="features-bold-text">Personalized posts</span>{" "}
        leveraging your LinkedIn data
      </div>
    ),
  },
  {
    text: (
      <div className="features-normal-text">
        <span className="features-bold-text">1000+ domain</span>
        -specific Recommended post ideas.
      </div>
    ),
  },
  {
    text: (
      <div className="features-normal-text">
        Images from{" "}
        <span className="features-bold-text">
          Google, Unsplash, Pexels, Pixabay
        </span>
      </div>
    ),
  },
  {
    text: (
      <div className="features-normal-text">
        <span className="features-bold-text">Add opinions</span> to Post for
        authenticity.
      </div>
    ),
  },
  {
    text: (
      <div className="features-normal-text">
        <span className="features-bold-text">500+ High-Engaging</span> curated
        posts.
      </div>
    ),
  },
  {
    text: (
      <div className="features-normal-text">
        Generate
        <span className="features-bold-text"> hooks, CTAs easily</span>
      </div>
    ),
  },

  {
    text: (
      <div className="features-normal-text">
        Our
        <span className="features-bold-text"> Research layer</span> gets
        relevant data for posts
      </div>
    ),
  },
  {
    text: (
      <div className="features-normal-text">
        Preview & <span className="features-bold-text">Schedule posts</span> for
        60 days.
      </div>
    ),
  },
  {
    text: (
      <div className="features-normal-text">
        Edit post with
        <span className="features-bold-text"> bold, italics, emojis, etc</span>
      </div>
    ),
  },
  {
    text: (
      <div className="features-normal-text">
        <span className="features-bold-text"> 24/7</span> Dedicated customer
        support directly from founders
      </div>
    ),
  },

  {
    text: (
      <div className="features-normal-text">
        Save and Edit
        <span className="features-bold-text"> Drafts. </span>
      </div>
    ),
  },

  {
    text: (
      <div className="features-normal-text">
        Generate{" "}
        <span className="features-bold-text"> AI Carousel and Canva</span>{" "}
        (releasing this month)
      </div>
    ),
  },
];
