import ReactGA from "react-ga4";

export const upgradePanelButtonClick = (data) =>
  ReactGA.event("upgrade_panel_button_click", {
    category: "click",
    ...data, //days_remaining
  });

export const proceedToPaymentClick = (data) =>
  ReactGA.event("proceed_to_payment_click", {
    category: "click",
    ...data, // plan_name, plan_price, plan_currency
  });
export const upgrade_plan_click = (data) =>
  ReactGA.event("upgrade_plan_click", {
    category: "click",
    ...data, //plan_name, plan_price, plan_currency
  });
export const cancelSubscriptionClick = () =>
  ReactGA.event("cancel_subscription_click", {
    category: "click",
  });

export const upgradeSuccess = (data) =>
  ReactGA.event("upgrade_successfull", {
    category: "click",
    ...data, // days_remaining
  });

export const upgradeAccountClick = (data) => {
  ReactGA.event("upgrade_accounts_page_click", {
    category: "click",
    ...data, // days_remaining
  });
};

export const upgradePageOverlayLockClickEvent = (data) => {
  ReactGA.event("upgrade_overlay_click", {
    category: "click",
    ...data, // days_remaining
  });
};

export const confirmCancelSubscriptionClick = () => {
  ReactGA.event("confirm_cancel_subscription_click", {
    category: "click",
  });
};

export const cancelSubscriptionSuccess = () => {
  ReactGA.event("subscription_cancel_success", {
    category: "click",
  });
};
