import React from "react";

const StillConfused = ({ isMobile }) => {
  return isMobile ? (
    <div className="still-confused-main-container">
      <div className="still-confused-text-box">
        <div className="still-confused-title">
          Still not convinced? Let’s talk.
        </div>
        <div className="still-confused-description">
          Connect with the team to learn how ReachifyMe can benefit you
        </div>
      </div>
      <div className="still-confused-image">
        <img
          src="/image/pricing/still-confused-girl.svg"
          alt="still-confused-girl-image"
          style={{ height: "100%", width: "100%", objectFit: "cover" }}
        />
      </div>
      <div className="call-now-box">Call us On 9731540246</div>
    </div>
  ) : (
    <div className="still-confused-main-container">
      <div className="still-confused-image">
        <img
          src="/image/pricing/still-confused-girl.svg"
          alt="still-confused-girl-image"
          style={{ height: "100%", width: "100%", objectFit: "cover" }}
        />
      </div>
      <div className="still-confused-text-box">
        <div className="still-confused-title">
          Still not convinced? Let’s talk.
        </div>
        <div className="still-confused-description">
          Connect with the team to learn how ReachifyMe can benefit you
        </div>
      </div>
      <div className="call-now-box">Call us On 9731540246</div>
    </div>
  );
};

export default StillConfused;
