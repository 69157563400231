import React, { useEffect, useRef, useState } from "react";
import PaymentPlanCardModal from "./components/PaymentPlanCardModal";
import PaymentPlanCard from "./components/PaymentPlanCard";
import {
  paymentComponentBoxStrings,
  paymentComponentBoxTypeStrings,
} from "../../constant/paymentString";
import usePaymentApi from "./paymentApi";
import { useDispatch, useSelector } from "react-redux";
import PaymentVerifyingModal from "./components/PaymentVerifyingModal";
import PlanPaymentFailedModal from "./components/PlanPaymentFailedModal";
import PricingLanding from "./components/PricingLanding";
import { setOpenTimer } from "../../reducers/slices/paymentOfferTimerSlice";
import { usePaymentGateway } from "./paymentgateway";
import { usePayment } from "./provider/usePayment";
import PaymentUpdateConfirmationModal from "./components/PaymentUpdateConfirmationModal";
import { proceedToPaymentClick } from "../../utils/paymentEvents";

export const PaymentStep = {
  PAYMENT_CARD: "PAYMENT_CARD",
  INITIATION: "INITIATION",
  PG_FLOW: "PG_FLOW",
  CHECKOUT_FAILED: "CHECKOUT_FAILED",
  CONFIRMATION: "CONFIRMATION",
  INITIATION_FAILED: "INITIATION_FAILED",
  CONFIRMATION_FAILED: "CONFIRMATION_FAILED",
  SUCCESS: "SUCCESS",
};

const PaymentComponentBox = (props) => {
  return <PaymentComponentBoxChildren {...props} />;
};

export default PaymentComponentBox;

const PaymentComponentBoxChildren = ({
  isPaymentComponentOpen,
  setIsPaymentComponentOpen,
  paymentComponentType,
  useCase,
  freePlanModal,
  paymentFailedModal,
  msg,
  pricingLanding = false,
}) => {
  const resolver = useRef(null);
  const { isPaymentModalOpen, initPaymentFlow, step } = usePayment();
  const userId = useSelector((state) => state.userData?.userId);
  const activeSubscription = useSelector(
    (state) => state.payment.activeSubscription,
  );
  const dispatch = useDispatch();
  const isOpenTimerSet = useSelector(
    (state) => state.paymentOfferTimer.openTimer,
  );
  const [
    isPaymentUpdateConfirmationModalOpen,
    setIsPaymentUpdateConfirmationModalOpen,
  ] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);

  useEffect(() => {
    const openTimer = new Date().getTime();
    if (!isOpenTimerSet) {
      dispatch(setOpenTimer(openTimer));
    }
  }, []);
  const initSubscriptionHandler = (selectedPlan) => {
    console.log("selected Plan", selectedPlan);
    const activePaymentSubscription =
      activeSubscription?.payment?.paymentSubscription;
    if (
      activePaymentSubscription?.subscriptionId &&
      (activePaymentSubscription?.subscriptionStatus === "ACTIVE" ||
        activePaymentSubscription?.subscriptionStatus === "AUTHENTICATED")
    ) {
      console.log("already have plan");
      setSelectedPlan(selectedPlan);
      setIsPaymentUpdateConfirmationModalOpen(true);
      return;
    }
    initPaymentFlow(selectedPlan.planId);
    proceedToPaymentClick({
      plan_name: selectedPlan.name,
      plan_price:
        selectedPlan.discountedPrice / selectedPlan.priceConversionFactor,
      plan_currency: selectedPlan.currencyCode,
      user: userId,
    });
  };

  const handleConfirm = () => {
    initPaymentFlow(selectedPlan.planId);
    setIsPaymentUpdateConfirmationModalOpen(false);
    proceedToPaymentClick({
      plan_name: selectedPlan.name,
      plan_price:
        selectedPlan.discountedPrice / selectedPlan.priceConversionFactor,
      plan_currency: selectedPlan.currencyCode,
      user: userId,
    });
  };

  const handleCancel = () => {
    setIsPaymentUpdateConfirmationModalOpen(false);
  };
  return (
    <>
      {isPaymentUpdateConfirmationModalOpen && (
        <PaymentUpdateConfirmationModal
          handleCancel={handleCancel}
          handleConfirm={handleConfirm}
        />
      )}
      {!paymentFailedModal && pricingLanding && (
        <PricingLanding
          setOpen={setIsPaymentComponentOpen}
          paymentComponentType={paymentComponentType}
          useCase={useCase}
          isRazorpayModalOpen={isPaymentModalOpen}
          msg={msg}
          initSubscriptionHandler={initSubscriptionHandler}
        />
      )}
      {!paymentFailedModal &&
        !pricingLanding &&
        paymentComponentType === paymentComponentBoxTypeStrings.modal &&
        (step === PaymentStep.PAYMENT_CARD ||
          step === PaymentStep.INITIATION) && (
          <PaymentPlanCardModal
            open={isPaymentComponentOpen}
            setOpen={setIsPaymentComponentOpen}
            paymentComponentType={paymentComponentType}
            useCase={useCase}
            msg={msg}
            initSubscriptionHandler={initSubscriptionHandler}
          />
        )}
      {paymentComponentType === paymentComponentBoxTypeStrings.modal &&
        step === PaymentStep.PAYMENT_CARD &&
        paymentFailedModal && (
          <PlanPaymentFailedModal setOpen={setIsPaymentComponentOpen} />
        )}
      {!pricingLanding &&
        paymentComponentType === paymentComponentBoxTypeStrings.static && (
          <PaymentPlanCard
            setOpen={setIsPaymentComponentOpen}
            msg={msg}
            paymentComponentType={paymentComponentType}
            useCase={useCase}
            initSubscriptionHandler={initSubscriptionHandler}
          />
        )}
    </>
  );
};
