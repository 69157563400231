import {
  createContext,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from "react";
import usePaymentGateway, { CheckoutSuccessResult } from "../paymentgateway";
import { useConfirmPayment, useInitPayment } from "../api/payment";
import {
  InitPaymentResponse,
  PaymentDetailsType,
  SubscriptionStatus,
} from "../types/payment";
import PaymentStatusModal from "../components/PaymentStatusModal";
import PaymentVerifyingModal from "../components/PaymentVerifyingModal";

export type PaymentStep =
  | "PAYMENT_CARD"
  | "INITIATION"
  | "PG_FLOW"
  | "CHECKOUT_FAILED"
  | "CONFIRMATION"
  | "INITIATION_FAILED"
  | "SUCCESS"
  | "CONFIRMATION_FAILED";

export interface IPaymentGatewayConsumerProps {
  initPaymentFlow?: (planId: string) => void;
  isLoaded: boolean;
  isPaymentModalOpen: boolean;
  isPaymentInProgress: boolean;
  step: PaymentStep;
}

const PaymentGatewayContext = createContext<IPaymentGatewayConsumerProps>({
  initPaymentFlow: () => {
    throw Error("Payment Gateway context has not been initialized");
  },
  isLoaded: false,
  isPaymentModalOpen: false,
  isPaymentInProgress: false,
  step: "PAYMENT_CARD",
});

const { Consumer: PaymentGatewayConsumer } = PaymentGatewayContext;

export function PaymentProvider({ children }: { children: ReactNode }) {
  const [step, setStep] = useState<PaymentStep>("PAYMENT_CARD");

  // Mutations
  const initPaymentMutation = useInitPayment({
    mutationConfig: {
      onSuccess: (data: InitPaymentResponse) => {
        const subscriptionStatus =
          data.paymentDetails.subscription?.subscriptionStatus;
        if (
          (data.paymentDetails.type === PaymentDetailsType.FREE &&
            data.paymentDetails.userSubscription) ||
          subscriptionStatus === SubscriptionStatus.AUTHENTICATED ||
          subscriptionStatus === SubscriptionStatus.ACTIVE
        ) {
          setStep("SUCCESS");
          return;
        }
        setStep("PG_FLOW");
        openPaymentGateway(data);
      },
      onError: () => {
        setStep("INITIATION_FAILED");
      },
    },
  });
  const confirmPaymentMutation = useConfirmPayment({
    mutationConfig: {
      onSuccess: () => {
        console.log("Payment confirmed");
        setStep("SUCCESS");
      },
      onError: () => {
        console.log("Payment confirmation failed");
        setStep("CONFIRMATION_FAILED");
      },
    },
  });

  const onCheckoutSuccess = (result: CheckoutSuccessResult) => {
    setStep("CONFIRMATION");
    confirmPaymentMutation.mutate({
      subscriptionId: result.subscriptionId,
      data: {
        pgPaymentId: result.pgPaymentId || null,
        pgSubscriptionId: result.pgSubscriptionId || null,
        pgSignature: result.pgSignature || null,
        pgOrderId: result.pgOrderId || null,
      },
    });
  };

  const onCheckoutFailure = () => {
    setStep("CHECKOUT_FAILED");
  };

  const onCheckoutDismissed = () => {
    setStep("PAYMENT_CARD");
  };

  const {
    isRazorPayLoaded,
    isLemonSqueezyLoaded,
    isPaymentModalOpen,
    openPaymentGateway,
  } = usePaymentGateway({
    handlers: {
      onCheckoutSuccess,
      onCheckoutFailure,
      onCheckoutDismissed,
    },
  });

  const initPaymentFlow = useCallback((planId: string) => {
    if (step !== "PAYMENT_CARD") {
      // TODO ("Show toast that payment is already in progress")
      return;
    }
    setStep("INITIATION");
    initPaymentMutation.mutate({ data: { planId: planId } });
  }, []);

  const showPaymentCard = () => {
    setStep("PAYMENT_CARD");
  };

  const paymentProviderContextValue = useMemo(
    () => ({
      initPaymentFlow,
      isLoaded: isLemonSqueezyLoaded && isRazorPayLoaded,
      isPaymentModalOpen: isPaymentModalOpen,
      isPaymentInProgress: false,
      step,
    }),
    [isLemonSqueezyLoaded, isRazorPayLoaded, isPaymentModalOpen, step],
  );
  const PaymentStatusModalProps = {
    paymentStatus: step,
    showPaymentCard: showPaymentCard,
  };

  return (
    <PaymentGatewayContext.Provider value={paymentProviderContextValue}>
      <>
        <PaymentStatusModal {...PaymentStatusModalProps} />
        {step === "CONFIRMATION" && <PaymentVerifyingModal />}
        {children}
      </>
    </PaymentGatewayContext.Provider>
  );
}

export { PaymentGatewayConsumer, PaymentGatewayContext };
