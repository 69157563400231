import { createSlice } from "@reduxjs/toolkit";

const contentEditingSlice = createSlice({
  name: "contentEditing",
  initialState: {
    options: null,
  },
  reducers: {
    SetContentEditingOptions: (state, action) => {
      state.options = action.payload;
    },
  },
});

export default contentEditingSlice.reducer;
export const { SetContentEditingOptions } = contentEditingSlice.actions;
