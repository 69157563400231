import React from "react";

const PricingLandingPaymentHeader = () => {
  return (
    <>
      <div className="payment-plan-card_body_up_title">Upgrade your Plan</div>
      <div className="payment-plan-card_body_up_trustmarker">
        <img
          className="payment-plan-card-body-up-trustmarker-img"
          src="image/payment/AvatarStack.svg"
          alt="plan"
          style={{ width: "74px" }}
        />
        <span className="trustmarker-text">
          Trusted by <span className="trustmarker-text-highlight">10000+</span>{" "}
          professionals on
        </span>
        <img src="image/payment/linkedInLogo.svg" alt="plan" />
      </div>{" "}
    </>
  );
};

export default PricingLandingPaymentHeader;
