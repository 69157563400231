import React from "react";
import PricingLandingPaymentHeader from "./PricingLandingPaymentHeader";
import PricingLandingPaymentCard from "./PricingLandingPaymentCard";

const PricingLandingPayment = ({
  setCurrentStep,
  setOpen,
  displayRazorpay,
  currentStep,
  isRazorpayModalOpen,
  setIsRazorpayModalOpen,
  availableSubscriptions,
  initializingPayment,
  setInitializingPayment,
  userId,
  paymentButtonDisabled,
  setPaymentButtonDisabled,
  initSubscriptionHandler,
  getAvailableSubscriptions,
  setIsVerifyingPayment,
  isVerifyingPayment,
  isMobile
  }) => {
  return (
    <div className="right-main-container">
      {/* payment */}
      <PricingLandingPaymentHeader />
      <PricingLandingPaymentCard
        setCurrentStep={setCurrentStep}
        setOpen={setOpen}
        displayRazorpay={displayRazorpay}
        currentStep={currentStep}
        setIsRazorpayModalOpen={setIsRazorpayModalOpen}
        isRazorpayModalOpen={isRazorpayModalOpen}
        availableSubscriptions={availableSubscriptions}
        userId={userId}
        initializingPayment={initializingPayment}
        setInitializingPayment={setInitializingPayment}
        paymentButtonDisabled={paymentButtonDisabled}
        setPaymentButtonDisabled={setPaymentButtonDisabled}
        initSubscriptionHandler={initSubscriptionHandler}
        getAvailableSubscriptions={getAvailableSubscriptions}
        setIsVerifyingPayment={setIsVerifyingPayment}
        isVerifyingPayment={isVerifyingPayment}
        isMobile={isMobile}
      />
      {/* call us */}
    </div>
  );
};

export default PricingLandingPayment;
