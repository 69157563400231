import { z } from "zod";
import { useMutation } from "@tanstack/react-query";
import { ConfirmPaymentResponse, InitPaymentResponse } from "../types/payment";
import { MutationConfig } from "../../../lib/reactQuery";
import request from "../../../lib/axiosRequest";

/**
 * Init Payment API
 */
export const initPaymentSchema = z.object({
  planId: z.string().min(1, "PlanId is required"),
});

export type InitPaymentInput = z.infer<typeof initPaymentSchema>;

export const payment = async ({
  data,
}: {
  data: InitPaymentInput;
}): Promise<InitPaymentResponse> => {
  const response = await request<InitPaymentInput, InitPaymentResponse>(
    "POST",
    "/subscription/payment/init",
    data,
  );
  return Promise.resolve(response!!);
};

export const useInitPayment = ({
  mutationConfig,
}: {
  mutationConfig?: MutationConfig<typeof payment>;
}) => {
  const { onSuccess, ...restConfig } = mutationConfig || {};
  return useMutation({
    onSuccess: (...args) => {
      onSuccess?.(...args);
    },
    ...restConfig,
    mutationFn: payment,
  });
};

/**
 * Confirm Payment API
 */
export const confirmPaymentSchema = z.object({
  pgPaymentId: z.string().nullable(),
  pgSubscriptionId: z.string().nullable(),
  pgSignature: z.string().nullable(),
  pgOrderId: z.string().nullable(),
});

export type ConfirmPaymentInput = z.infer<typeof confirmPaymentSchema>;

export const confirmPayment = async ({
  subscriptionId,
  data,
}: {
  subscriptionId: string;
  data: ConfirmPaymentInput;
}): Promise<ConfirmPaymentResponse> => {
  const response = await request<ConfirmPaymentInput, ConfirmPaymentResponse>(
    "PATCH",
    `/subscription/payment/subscriptions/${subscriptionId}/confirm`,
    data,
  );
  if (response?.poll) {
    return Promise.reject("Poll is required");
  }
  return Promise.resolve(response!!);
};

export const useConfirmPayment = (options?: {
  mutationConfig?: MutationConfig<typeof confirmPayment>;
}) => {
  const { onSuccess, retry, ...restConfig } = options?.mutationConfig || {};
  return useMutation({
    onSuccess: (...args) => {
      onSuccess?.(...args);
    },
    retry: 64,
    retryDelay: (failureCount: number): number => {
      const delayFactor = Math.ceil(failureCount / 4);
      return delayFactor * 700;
    },
    ...restConfig,
    mutationFn: confirmPayment,
  });
};
