import React from "react";
import TestimonialCard from "./TestimonialCard";
import { useSelector } from "react-redux";
export function Testimonials({ testimonialsCardContent }) {
  const isMobile = useSelector((state) => state.device.isMobileDevice);
  const scrollRef = React.useRef(null);

  return (
    <div className="testimonials-main-container">
      {isMobile ? (
        <div className="testimonials-heading">Testimonials</div>
      ) : (
        <div className="testimonials-heading">Hear from Our Users</div>
      )}

      <div
        className="testimonials-display-container no-scrollbar"
        ref={scrollRef}
      >
        {testimonialsCardContent.map((testimonial, index) => (
          <TestimonialCard
            key={index}
            profileImg={testimonial.profileImg}
            name={testimonial.name}
            role={testimonial.role}
            country={testimonial.country}
            description={testimonial.desc}
          />
        ))}
      </div>

    </div>
  );
}
