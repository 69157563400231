import React, { useState } from "react";
import CustomModal from "../../Modal/CustomModal";
import Buttons from "../../custombuttons/Buttons";
import { useSelector } from "react-redux";
import { getLastBilledValue } from "../../../utils/payment";
import usePaymentApi from "../paymentApi";
import { openInNewTab } from "../../../utils/linkUtil";

const style = {
  chip: {
    minWidth: "20%",
  },
  chipName: {
    fontSize: "16px",
    fontWeight: "600",
    color: "#121212",
  },
  chipValue: {
    fontSize: "14px",
    fontWeight: "400",
    color: "#808080",
  },
};

const PlanPaymentFailedModal = ({ setOpen }) => {
  const { getUpdatePaymentMethodLink } = usePaymentApi();
  const currentPaymentSubscription = useSelector(
    (state) => state.payment?.currentPaymentSubscription,
  );
  const lastUserSubscription = useSelector(
    (state) => state.payment?.lastUserSubscription,
  );

  const [showLoader, setShowLoader] = useState(false);

  const updatePaymentMethod = () => {
    getUpdatePaymentMethodLink({
      subscriptionId: currentPaymentSubscription?.subscriptionId,
      setShowLoader,
      openUpdatePaymentLink: ({ updatePaymentMethodUrl }) => {
        openInNewTab(updatePaymentMethodUrl);
      },
    });
  };
  return (
    <CustomModal
      open={true}
      setOpen={setOpen}
      width={"900px"}
      showCancelButton={true}
    >
      <div style={{ fontFamily: "Poppins" }}>
        <div style={{ fontSize: "24px", fontWeight: "500", color: "#3F3B31" }}>
          Plan payment failed
        </div>
        <div
          style={{
            marginTop: "24px",
            borderRadius: "16px",
            border: "1px solid #E7E8ED",
            boxShadow: "0px 18px 25px 0px #A4A4A426",
            padding: "16px",
            display: "flex",
            alignItems: "center",
            gap: "16px",
          }}
        >
          <div>
            <img src="image/payment/exclamation.svg" alt="plan" />
          </div>
          <div style={style.chip}>
            <div style={style.chipName}>Plan</div>
            <div style={style.chipValue}>
              {currentPaymentSubscription?.pricingPlan?.name}
            </div>
          </div>
          <div style={style.chip}>
            <div style={style.chipName}>Status</div>
            <div style={{ ...style.chipValue, color: "#E41E27" }}>
              Payment failed
            </div>
          </div>
          <div style={style.chip}>
            <div style={style.chipName}>Billing Cycle</div>
            <div style={style.chipValue}>
              {currentPaymentSubscription?.pricingPlan?.interval}{" "}
              {currentPaymentSubscription?.pricingPlan?.validationPeriodType}
            </div>
          </div>
          <div style={style.chip}>
            <div style={style.chipName}>Last Billed</div>
            <div style={style.chipValue}>
              {getLastBilledValue(
                lastUserSubscription?.payment?.createdOn,
                true,
              )}
            </div>
          </div>
        </div>
        <div
          style={{
            fontSize: "14px",
            fontWeight: "400",
            color: "#4D4D4D",
            margin: "16px 0px",
          }}
        >
          Change payment method for the current plan to continue using the
          platform.
        </div>
        <div style={{ fontSize: "16px", marginTop: "24px" }}>
          <Buttons
            text={"Update payment method"}
            variant={showLoader ? "loader" : "gradient"}
            width={"100%"}
            clickHandler={updatePaymentMethod}
          />
        </div>
      </div>
    </CustomModal>
  );
};

export default PlanPaymentFailedModal;
