import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { InitPaymentResponse, PaymentGateway } from "../types/payment";
import { useSelector } from "react-redux";

export interface PaymentGatewayHandlers {
  onCheckoutSuccess?: (result: CheckoutSuccessResult) => void;
  onCheckoutDismissed?: () => void;
  onCheckoutFailure?: () => void;
}

export interface CheckoutSuccessResult {
  subscriptionId: string;
  pgSignature?: string;
  pgOrderId?: string;
  pgSubscriptionId?: string;
  pgPaymentId?: string;
}

interface RazorpaySuccessHandlerArgs {
  razorpay_signature: string;
  razorpay_order_id?: string;
  razorpay_payment_id: string;
  razorpay_subscription_id?: string;
}

export interface RazorpayOptions {
  key: string;
  amount?: string;
  currency?: string;
  name: string;
  description?: string;
  image?: string;
  order_id?: string;
  handler?: (args: RazorpaySuccessHandlerArgs) => void;
  prefill?: {
    name?: string;
    email?: string;
    contact?: string;
    method?: "card" | "netbanking" | "wallet" | "emi" | "upi";
  };
  notes?: {};
  theme?: {
    hide_topbar?: boolean;
    color?: string;
    backdrop_color?: string;
  };
  modal?: {
    backdropclose?: boolean;
    escape?: boolean;
    handleback?: boolean;
    confirm_close?: boolean;
    ondismiss?: () => void;
    animation?: boolean;
  };
  subscription_id?: string;
  subscription_card_change?: boolean;
  recurring?: boolean;
  callback_url?: string;
  redirect?: boolean;
  customer_id?: string;
  timeout?: number;
  remember_customer?: boolean;
  readonly?: {
    contact?: boolean;
    email?: boolean;
    name?: boolean;
  };
  hidden?: {
    contact?: boolean;
    email?: boolean;
  };
  send_sms_hash?: boolean;
  allow_rotation?: boolean;
  retry?: {
    enabled?: boolean;
    max_count?: boolean;
  };
  config?: {
    display: {
      language: "en" | "ben" | "hi" | "mar" | "guj" | "tam" | "tel";
    };
  };
}

class Razorpay {
  private options: RazorpayOptions;
  private rzrpayInstannce: any;

  constructor(options: RazorpayOptions) {
    this.options = options;
    if (typeof window !== "undefined")
      this.rzrpayInstannce = new (window as any).Razorpay(this.options);
  }

  public on(event: string, callback: Function) {
    this.rzrpayInstannce.on(event, callback);
  }

  public open() {
    this.rzrpayInstannce.open();
  }
}

class LemonSqueezy {
  /**
   * Initialises Lemon.js on your page.
   * @param options - An object with a single property, eventHandler, which is a function that will be called when
   * Lemon.js emits an event.
   */
  public static setUp(options: { eventHandler: (event: any) => void }) {
    (window as any).LemonSqueezy.Setup(options);
  }

  /**
   * Refreshes `lemonsqueezy-button` listeners on the page.
   */
  public static refresh() {
    (window as any).LemonSqueezy.Refresh();
  }

  /**
   * Opens a given Lemon Squeezy URL, typically these are Checkout or Payment Details Update overlays.
   * @param url - The URL to open.
   */
  public static openUrl(url: string) {
    console.log(`Opening Lemon Squeezy PG Static: ${url}`);
    (window as any).LemonSqueezy.Url.Open(url);
  }

  /**
   * Closes the current opened Lemon Squeezy overlay checkout window.
   */
  public static closeWindow() {
    (window as any).LemonSqueezy.Url.Close();
  }

  /**
   * Retrieve the affiliate tracking ID
   */
  public static getAffiliateId(): string {
    return (window as any).LemonSqueezy.Affiliate.GetID();
  }

  /**
   * Append the affiliate tracking parameter to the given URL
   * @param url - The URL to append the affiliate tracking parameter to.
   */
  public static buildAffiliateTrackingUrl(url: string): string {
    return (window as any).LemonSqueezy.Affiliate.Build(url);
  }
}

const usePaymentGateway = ({
  handlers,
}: {
  handlers?: PaymentGatewayHandlers;
}) => {
  const RAZORPAY_SCRIPT = "https://checkout.razorpay.com/v1/checkout.js";
  const LEMONSQUEEZY_SCRIPT = "https://app.lemonsqueezy.com/js/lemon.js";

  const userDetails = useSelector((state: any) => state.userData);
  const linkedInDetails = useSelector(
    (state: any) => state.linkedinConnect.linkedinConnectData,
  );

  const email = userDetails?.email;
  const fullName = `${userDetails?.userAttributes?.firstName ?? linkedInDetails[0]?.firstName ?? ""} ${
    userDetails?.userAttributes?.lastName ?? linkedInDetails[0]?.lastName ?? ""
  }`;

  const isClient = useMemo(() => typeof window !== "undefined", []);

  const [isRazorPayLoaded, setIsRazorPayLoaded] = useState(false);
  const [isLemonSqueezyLoaded, setIsLemonSqueezyLoaded] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);

  const checkScriptLoaded = useCallback((object: string) => {
    if (!isClient || !(object in window)) return false;
    return true;
  }, []);

  const loadScript = useCallback(
    (
      id: string,
      scriptUrl: string,
      updateState: Dispatch<SetStateAction<boolean>>,
    ) => {
      if (!isClient) return; // Don't execute this function if it's rendering on server side
      return new Promise((resolve, reject) => {
        const scriptTag = document.createElement("script");
        scriptTag.id = id;
        scriptTag.src = scriptUrl;
        scriptTag.defer = true;
        scriptTag.onload = () => {
          updateState(true);
          resolve(scriptTag);
        };
        scriptTag.onerror = (err) => reject(err);
        document.body.appendChild(scriptTag);
      });
    },
    [],
  );

  const openRazorPay = (initiationResponse: InitPaymentResponse) => {
    console.log("Opening Razorpay");
    const options: RazorpayOptions = {
      key: initiationResponse.paymentGatewayDetails!!.key!!,
      subscription_id:
        initiationResponse.paymentDetails.subscription?.pgSubscriptionId,
      recurring: true,
      name: initiationResponse.businessDetails.name,
      description: initiationResponse.businessDetails.description,
      image: initiationResponse.businessDetails.logo,
      handler: function (response: RazorpaySuccessHandlerArgs) {
        setIsPaymentModalOpen(false);
        handlers?.onCheckoutSuccess?.({
          subscriptionId:
            initiationResponse.paymentDetails.subscription?.subscriptionId!!,
          pgSubscriptionId: response.razorpay_subscription_id,
          pgPaymentId: response.razorpay_payment_id,
          pgSignature: response.razorpay_signature,
        });
      },
      prefill: {
        name: fullName.trim() ? fullName.trim() : undefined,
        email: email,
      },
      theme: {
        color: "#FDAE38",
      },
      modal: {
        ondismiss: function () {
          document.body.style.overflow = "";
          setIsPaymentModalOpen(false);
          handlers?.onCheckoutDismissed?.();
        },
      },
      hidden: {
        contact: true,
        email: true,
      },
      readonly: { email: true },
      remember_customer: true,
    };
    const razorpayInstance = new Razorpay(options);
    razorpayInstance.on("payment.failed", function () {
      handlers?.onCheckoutFailure?.();
    });
    razorpayInstance.open();
    setIsPaymentModalOpen(true);
    console.log("Full Name:", fullName);
  };

  const openLemonSqueezy = (initiationResponse: InitPaymentResponse) => {
    console.log("Trying to Open and SetUp LemonSqueezy PG");
    LemonSqueezy.setUp({
      eventHandler: (event: any) => {
        console.log("Received Event:", event);
        if (event === "close") {
          setIsPaymentModalOpen(false);
          handlers?.onCheckoutDismissed?.();
          return;
        }
        if (event.event) {
          switch (event.event) {
            case "Checkout.Success":
              handlers?.onCheckoutSuccess?.({
                subscriptionId:
                  initiationResponse.paymentDetails.subscription
                    ?.subscriptionId!!,
                pgOrderId: event?.data?.order?.data?.id,
              });
              LemonSqueezy.closeWindow();
              setIsPaymentModalOpen(false);
              break;
            case "PaymentMethodUpdate.Mounted":
              break;
            case "PaymentMethodUpdate.Closed":
              break;
            case "PaymentMethodUpdate.Updated":
              break;
            default:
          }
        }
      },
    });
    console.log("Opening Lemon Squeezy PG");
    LemonSqueezy.openUrl(initiationResponse.paymentDetails.checkoutUrl!!);
    setIsPaymentModalOpen(true);
  };

  const openPaymentGateway = (initiationResponse: InitPaymentResponse) => {
    console.log("initiation", initiationResponse);
    switch (initiationResponse.paymentDetails.paymentGateway) {
      case PaymentGateway.RAZOR_PAY:
        console.log("razor pay");
        openRazorPay(initiationResponse);
        break;
      case PaymentGateway.LEMON_SQUEEZY:
        console.log("lemon squeezy");
        openLemonSqueezy(initiationResponse);
        break;
      default:
        console.log("default payment");
        throw new Error("Invalid payment details");
    }
  };

  useEffect(() => {
    if (!checkScriptLoaded("Razorpay")) {
      (async () => {
        try {
          await loadScript(
            "razorpay-pg-script",
            RAZORPAY_SCRIPT,
            setIsRazorPayLoaded,
          );
        } catch (error: any) {
          throw new Error(error);
        }
      })();
    } else {
      setIsRazorPayLoaded(true);
    }

    if (!checkScriptLoaded("LemonSqueezy")) {
      (async () => {
        try {
          await loadScript(
            "lemonsqueezy-pg-script",
            LEMONSQUEEZY_SCRIPT,
            setIsLemonSqueezyLoaded,
          );
          (window as any).createLemonSqueezy();
          console.log("LemonSqueezy Script loaded");
        } catch (error: any) {
          throw new Error(error);
        }
      })();
    } else {
      setIsLemonSqueezyLoaded(true);
    }
  });

  return {
    isRazorPayLoaded,
    isLemonSqueezyLoaded,
    isPaymentModalOpen,
    openPaymentGateway,
  };
};

export default usePaymentGateway;
