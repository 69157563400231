import { useState, useEffect } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import FullPageLoader from "../fullPageLoader";
import FullPageError from "../fullPageError";
import useProtectedRouteApis from "./api/protectedRouteApi";
import { useSelector } from "react-redux";
import { strings } from "../../constant/strings";
import { PaymentProvider } from "../../sharedcomponents/payment/provider/paymentProvider";

const ProtectedRoute = () => {
  // Constants

  // Refs

  // Set Initial States
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isReload, setReload] = useState(true);

  // Get Data from redux store
  const authData = useSelector((state) => state.auth);
  const userData = useSelector((state) => state.userData);
  const storageToken = useSelector((state) => state.storage);
  const linkedInProfiles = useSelector((state) => state.linkedinConnect);
  const activeSubscription = useSelector(
    (state) => state.payment.activeSubscription
  );

  const {
    getUserDetails,
    getStorageToken,
    getLinkedInProfiles,
    getActiveUserSubscription,
  } = useProtectedRouteApis();

  // Hook Initialisaction
  const location = useLocation();

  const animPromise = () =>
    new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve();
      }, 2000);
    });

  useEffect(() => {
    console.log(authData.isLoggedIn);
    if (authData.isLoggedIn && isReload) {
      console.log("Loading Fetching...");
      setIsLoading(true);
      const promises = [];
      if (!userData.userId) {
        promises.push(getUserDetails());
      }
      if (!storageToken.token) {
        promises.push(getStorageToken());
      }
      if (!linkedInProfiles.linkedinConnectStatus.isSuccess) {
        promises.push(getLinkedInProfiles());
      }
      if (!activeSubscription) {
        promises.push(getActiveUserSubscription());
      }

      console.log(promises.length); //
      promises.push(animPromise());
      //const result = await Promise.allSettled(promises);
      Promise.all(promises)
        .then((result) => {
          console.log(result);
          setIsError(false);
          setIsLoading(false);
          setReload(false);
        })
        .catch((error) => {
          console.log("****************************");
          setIsError(true);
          setIsLoading(false);
          setReload(false);
        });
    }
  }, [isReload]);

  const handleReload = () => {
    setReload(true);
  };

  return authData.isLoggedIn ? (
    isLoading ? (
      <FullPageLoader text={strings.DashboardLoadingText} />
    ) : isError ? (
      <FullPageError onReload={handleReload} />
    ) : (
      <PaymentProvider>
        <Outlet />
      </PaymentProvider>
    )
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default ProtectedRoute;
