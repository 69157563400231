import React from "react";
import CustomModal from "../../Modal/CustomModal";
import PaymentPlanCard from "./PaymentPlanCard";
import { useDispatch, useSelector } from "react-redux";
import { setCloseTimer } from "../../../reducers/slices/paymentOfferTimerSlice";

const PaymentPlanCardModal = ({
  open,
  setOpen,
  paymentComponentType,
  useCase,
  msg,
  initSubscriptionHandler,
}) => {
  const isMobile = useSelector((state) => state.device?.isMobileDevice);

  const dispatch = useDispatch();
  const setTimeWhileClosing = () => {
    const today = new Date().getTime();
    dispatch(setCloseTimer(today));
  };

  return (
    <>
      <CustomModal
        open={open}
        setOpen={setOpen}
        width={"1000px"}
        minwidth={"1000px"}
        showCancelButton={true}
        setTimeWhileClosing={setTimeWhileClosing}
        showMobileBar={false}
        showMobileCross={true}
        useCancelAsAbsolute={true}
        styles={{
          padding: isMobile ? "0px" : "24px 32px 24px 32px",
          paddingRight: isMobile ? "4px" : "auto",
          borderRadius: isMobile ? "25px 25px 0px 0px" : "24px 24px 16px 16px",
          paddingBottom: isMobile ? "0px" : "auto",
        }}
      >
        <PaymentPlanCard
          msg={msg}
          setOpen={setOpen}
          paymentComponentType={paymentComponentType}
          useCase={useCase}
          initSubscriptionHandler={initSubscriptionHandler}
        />
      </CustomModal>
    </>
  );
};

export default PaymentPlanCardModal;
