import React from "react";

const WhyReachifyMeCard = ({
  border,
  cardBackground,
  imgBackground,
  imgBorder,
  imgShadow,
  img,
  title,
  alt_img_text,
  highlightValue,
  highlightValueSub,
  description,
  summaryBoxBackground,
  summaryBoxTextColor,
  summaryBoxIconImg,
  summaryBoxIconAltText,
  summaryBox,
  reachifyMePrice,
  agencyPriceMonthly,
  agencyPriceYearly,
}) => {
  return (
    <div
      style={{ background: cardBackground, border: border }}
      className="whyReachifyMe-card-container"
    >
      <div className="whyReachifyMe-card-img-text">
        <div
          className="whyReachifyMe-card-img"
          style={{
            background: imgBackground,
            border: imgBorder ? "border: 1px solid #F5F5FF" : "none",
            boxShadow: imgShadow ? "8px 8px 43px 0px #0000001A" : "none",
          }}
        >
          <img src={img} alt={alt_img_text} />
        </div>
        <div className="whyReachifyMe-card-title">{title}</div>
      </div>
      <div className="whyReachifyMe-card-title" >{title}</div>
      <div style={{margin:"6px 0px"}}>
        <span className="whyReachifyMe-card-highlight-value" style={{fontFamily:"sans-serif"}}>
          {reachifyMePrice
            ? reachifyMePrice
            : agencyPriceMonthly
            ? agencyPriceMonthly
            : highlightValue}
        </span>{" "}
        <span className="whyReachifyMe-card-highlight-value-sub">
          {highlightValueSub}
        </span>
      </div>
      <div className="whyReachifyMe-card-description" style={{fontFamily:"sans-serif"}}>
        {agencyPriceYearly
          ? `= ${agencyPriceYearly} per year and few hours monthly.`
          : description}
      </div>
      <div
        className="whyReachifyMe-card-summary-container"
        style={{
          background: summaryBoxBackground,
          gap: summaryBoxIconAltText === "Wrong Cross Image" ? "4px" : "0px",
        }}
      >
        {/* <div
          style={{
            height: "16px",
            width: "16px",
            background:
              summaryBoxIconAltText === "Wrong Cross Image" ? null : "#ODAE6B",
          }}
        >
          {summaryBoxIconImg && (
            <img
              src={summaryBoxIconImg}
              alt={summaryBoxIconAltText}
              style={{ height: "100%", width: "100%", objectFit: "fill" }}
            />
          )}
        </div> */}
        <div
          className="whyReachifyMe-card-summary-text-container"
          style={{
            color: summaryBoxTextColor,
          }}
        >
          {summaryBox}
        </div>
      </div>
    </div>
  );
};

export default WhyReachifyMeCard;
